import DsButton, { DsButtonProps } from "../DsButton/DsButton";

export interface ToggleButtonCustProps {
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    icon?: JSX.Element;
}

export interface ToggleButtonProps extends DsButtonProps {
    selectedCust: ToggleButtonCustProps;
    deselectedCust: ToggleButtonCustProps;
    selected: boolean;
}

const ToggleButton = ({ selected, selectedCust, deselectedCust, ...props }: ToggleButtonProps) => {

    return (
        <DsButton
            icon={selected ? selectedCust.icon : deselectedCust.icon }
            buttonProps={{
                style: {
                    border: selected ? "1px solid " + selectedCust.borderColor : "1px solid " + deselectedCust.borderColor,
                    backgroundColor: selected ? selectedCust.backgroundColor : deselectedCust.backgroundColor,
                    color: selected ? selectedCust.textColor : deselectedCust.textColor
                }
            }}
            {...props}
        />
    )
}

export default ToggleButton;