import { Box, styled, Typography } from "@mui/material"
import LockOpen from "@mui/icons-material/LockOpen";
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from "@mui/icons-material/Lock";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import myTheme from "../../../myTheme"

export const OuterContainer = styled(Box)({
    width: "100%",
    boxSizing: "content-box",
    borderBottom: "3px solid " + myTheme.darkest
})

export const InnerContainer = styled(Box)({
    padding: "10px 15px"
})

export const Row = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    alignItems: "center"
})

export const NameText = styled(Typography)(({ theme }) => ({
    color: myTheme.light,
    fontSize: "30px",
    fontWeight: "bold",
    flex: 2,
    overflowX: "clip",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
        fontSize: "20px"
    }
}))

export const InfoBlock = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "5px",
    flex: 1
})

export const TeamText = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: "bold",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}))

export const Lock = styled(LockIcon)(({ theme }) => ({
    fontSize: "26px",
    color: myTheme.healthRed,
    [theme.breakpoints.down("sm")]: {
        fontSize: "20px"
    }
}))

export const Unlock = styled(LockOpen)(({ theme }) => ({
    fontSize: "26px",
    color: myTheme.medDark,
    [theme.breakpoints.down("sm")]: {
        fontSize: "20px"
    }
}))

export const Delete = styled(DeleteIcon)(({ theme }) => ({
    fontSize: "26px",
    color: myTheme.healthRed,
    [theme.breakpoints.down("sm")]: {
        fontSize: "20px"
    }
}))

export const StatBubble = styled(Box)(({ theme }) => ({
    backgroundColor: myTheme.primary,
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative",
    padding: "2px",
    [theme.breakpoints.down("sm")]: {
        width: "40px",
        height: "40px"
    }
}))

export const AcWrapper = styled(Box)(({ theme }) => ({
    width: "40px",
    height: "60px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: "relative",
    [theme.breakpoints.down("sm")]: {
        width: "30px",
        height: "45px"
    }
}))

export const StatBubbleText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    textAlignVertical: 'center',
    textAlign: 'center',
    fontSize: "30px",
    color: myTheme.light,
    fontWeight: 'bold',
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px"
    }
}))

export const StatBubbleTextSmall = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    textAlignVertical: 'center',
    textAlign: 'center',
    fontSize: "20px",
    color: myTheme.light,
    fontWeight: 'bold',
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        paddingBottom: "2px",
    }
}))

export const InfoButton = styled(LeaderboardIcon)(({ theme }) => ({
    textAlign: 'center',
    fontSize: "30px",
    color: myTheme.light,
    fontWeight: 'bold',
    [theme.breakpoints.down("sm")]: {
        fontSize: "24px"
    }
}))

export const HealthBarWrapper = styled(Box)({
    flexGrow: 1,
    padding: "0 20px"
})

export const DeadText = styled("span")({
    fontSize: "26px",
    color: myTheme.light
})

export const ArrowUp = styled(ArrowCircleUpOutlinedIcon)(({ theme }) => ({
    fontSize: "40px",
    color: myTheme.light,
    [theme.breakpoints.down("sm")]: {
        fontSize: "36px"
    }
}))

export const XpText = styled("span")({
    fontSize: "20px",
    fontStyle: "italic",
    color: myTheme.light
})