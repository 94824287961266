import { getAllCreatureStatBlockItems, getAllCreatures } from "../api/creatureApi";
import { getAllFriends } from "../api/friendApi";
import { getAllMagicItems } from "../api/magicItemApi";
import { getAllSpells } from "../api/spellApi";
import { setCreatures } from "../redux/slices/creatureSlice";
import { setFriends, setReceived, setSent } from "../redux/slices/friendSlice";
import { setMagicItems } from "../redux/slices/magicItemSlice";
import { setSpells } from "../redux/slices/spellSlice";
import { setStatblockItems } from "../redux/slices/statblockItemSlice";
import store from "../redux/store";

export const loadFromDb = async (tok: string) => {
    loadAllCreatures(tok);
    loadAllMagicItems(tok);
    loadAllSpells(tok);
    loadAllSbItems(tok);
    loadAllFriends(tok);
};

export const loadAllCreatures = async (tok: string) => {
    const creatures = await getAllCreatures(tok);
    if (creatures) {
        store.dispatch(setCreatures(creatures));
    }
};

export const loadAllMagicItems = async (tok: string) => {
    const magicItems = await getAllMagicItems(tok);
    if (magicItems) {
        store.dispatch(setMagicItems(magicItems));
    }
};

export const loadAllSpells = async (tok: string) => {
    const spells = await getAllSpells(tok);
    if (spells) {
        store.dispatch(setSpells(spells));
    }
};

export const loadAllSbItems = async (tok: string) => {
    const statblockItems = await getAllCreatureStatBlockItems(tok);
    if (statblockItems) {
        store.dispatch(setStatblockItems(statblockItems));
    }
};

export const loadAllFriends = async (tok: string) => {
    const friends = await getAllFriends(tok);
    if (friends) {
        store.dispatch(setFriends(friends.friends));
        store.dispatch(setSent(friends.sent));
        store.dispatch(setReceived(friends.received));
    }
}