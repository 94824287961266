import { Box } from '@mui/material';
import { Buffer } from 'buffer';
import React from 'react';
import { CustomIconProps } from '../../../types/MiscTypes';

const AcIcon  = ({ size, stroke = 'black', fill = 'none', strokeWidth = 10 }: CustomIconProps) => {
  
    const xml = `
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612">
            <defs>
                <style>
                    .cls-1 {
                        fill: ${fill};
                        stroke: ${stroke};
                        stroke-miterlimit: 10;
                        stroke-width: ${strokeWidth};
                    }
                </style>
            </defs>
            <path class="cls-1"
                d="M306,195C305,257,431,309,495,299c-1,120-74,242-189,298h0C191,541,118,419,117,299,181,309,307,257,306,195" />
        </svg>
    `
    const buff = Buffer.from(xml);
    const base64data = buff.toString("base64");

    return (
        <Box 
            sx={{
                width: size ? `${size}px` : "100%",
                height: size ? `${size}px` : "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}
            data-testid="acIcon__container"
        >
            <img src={`data:image/svg+xml;base64,${base64data }`} alt=""
                style={{
                    height: "100%"
                }}
            />
        </Box>
    )
}

export default AcIcon;