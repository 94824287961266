import { Box, styled } from "@mui/material"
import myTheme from "../myTheme"

export const OuterContainer = styled(Box)({
    height: "92.25vh",
    display: "flex",
    flexDirection: "column",
    boxSizing: "content-box"
})

export const HeaderContainer = styled(Box)({
    height: "6vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "content-box",
    zIndex: 100,
    backgroundColor: myTheme.primary,
    boxShadow: "0px 2px 5px " + myTheme.darkest
})

export const ContentContainer = styled(Box)({
    flexGrow: 1,
    overflowY: "scroll"
})