import { ISimpleSchema, ISource } from "./CreatureTypes";
import { IFriend } from "./FriendTypes";

export const SpellSortByOptions = {
    name: "Name",
    level: "Level"
}

export interface IDurationSchema {
    timeUnit: ISimpleSchema;
    time: number;
}

export interface IDistanceSchema {
    distanceUnit: ISimpleSchema;
    distance: number;
}

export interface ISpell {
    _id?: string;
    name: string;
    //attack_type?: ISimpleSchema;
    casting_time?: IDurationSchema;
    classes: ISimpleSchema[];
    components: ISimpleSchema[];
    concentration?: boolean;
    duration?: IDurationSchema;
    higher_level?: string;
    description?: string;
    level: number;
    material?: string;
    range?: IDistanceSchema;
    ritual?: boolean;
    school?: ISimpleSchema;
    subclasses: ISimpleSchema[];
    source?: ISource | string;
    visibility?: string;
    createdBy?: string;
    sharedWith?: IFriend[];
}