import { Box, Typography, styled } from "@mui/material";
import myTheme from "../../../myTheme";
import KillButton from "../../input/buttons/KillButton";
import DeleteButton from "../../input/buttons/DeleteButton";
import ToggleButton, { ToggleButtonCustProps } from "../../input/buttons/ToggleButton";
import { IoMdTrash } from "react-icons/io";
import { BiSave } from "react-icons/bi";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import Teams from "../../../constants/Teams.constants";
import { selectInitOrder, setInitOrder } from "../../../redux/slices/initSlice";
import Status from "../../../constants/CreatureStatus";
import { setClearInitActive } from "../../../redux/slices/modalSlice";

const GRAVEYARD = 'Graveyard';

const Container = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "10px"
})

const LabelText = styled(Typography)({
    color: myTheme.medGray,
    fontSize: "14px",
    textAlign: "center"
})

const StyledToggle = styled(ToggleButton)({
    width: "fit-content"
})

const ButtonRow = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px"
})

const selectedColors: ToggleButtonCustProps = {
    textColor: myTheme.darkest,
    backgroundColor: myTheme.medGray,
    borderColor: myTheme.medGray,
    icon: <BiSave fontSize={"20px"} />
}

const deselectedColors: ToggleButtonCustProps = {
    textColor: myTheme.medGray,
    backgroundColor: myTheme.bg,
    borderColor: myTheme.medGray,
    icon: <IoMdTrash fontSize={"20px"} />
}

const ClearInitModal = () => {

    const [saveEnemy, setSaveEnemy] = useState<boolean>(false);
    const [savePlayer, setSavePlayer] = useState<boolean>(true);
    const [saveAlly, setSaveAlly] = useState<boolean>(false);
    const [saveNeutral, setSaveNeutral] = useState<boolean>(false);
    const [saveEnv, setSaveEnv] = useState<boolean>(false);
    const [saveGrave, setSaveGrave] = useState<boolean>(true);

    const initOrder = useAppSelector(selectInitOrder);

    const dispatch = useAppDispatch();

    const getSelectedTeams = () => {
        const teams = [];
        if (saveEnemy) teams.push(Teams.ENEMY);
        if (savePlayer) teams.push(Teams.PLAYER);
        if (saveAlly) teams.push(Teams.ALLY);
        if (saveNeutral) teams.push(Teams.NEUTRAL);
        if (saveEnv) teams.push(Teams.ENVIRONMENT);
        if (saveGrave) teams.push(GRAVEYARD);
        return teams;
    }

    const handleDelete = () => {
        const selectedTeams = getSelectedTeams();
        // get only creatures that are locked, are members of selected teams, or if graveyard is excluded,
        // are DEAD
        const newList = initOrder.filter(c => c.locked || selectedTeams.includes(c.team) || (selectedTeams.includes(GRAVEYARD) && c.status === Status.DEAD))
        dispatch(setInitOrder(newList));
        dispatch(setClearInitActive(false));
    }

    const handleKill = () => {
        const selectedTeams = getSelectedTeams();
        const newList = [...initOrder]
        newList.forEach((c, i) => {
            if (!(c.locked || selectedTeams.includes(c.team) || (selectedTeams.includes(GRAVEYARD) && c.status === Status.DEAD))){
                const newCreature = {...c}
                newCreature.status = Status.DEAD
                newList[i] = newCreature
            }
        })
        dispatch(setInitOrder(newList));
        dispatch(setClearInitActive(false));
    }

    return (
        <Container>
            <Box>
                <LabelText>Locked creatures will not be deleted or killed.</LabelText>
                <LabelText>You may also select teams to exclude from being deleted or killed.</LabelText>
            </Box>

            <ButtonRow>
                <StyledToggle
                    label="Enemy"
                    selected={saveEnemy}
                    selectedCust={selectedColors}
                    deselectedCust={deselectedColors}
                    onClick={() => setSaveEnemy(prev => !prev)}
                />
                <StyledToggle
                    label="Player"
                    selected={savePlayer}
                    selectedCust={selectedColors}
                    deselectedCust={deselectedColors}
                    onClick={() => setSavePlayer(prev => !prev)}
                />
                <StyledToggle
                    label="Ally"
                    selected={saveAlly}
                    selectedCust={selectedColors}
                    deselectedCust={deselectedColors}
                    onClick={() => setSaveAlly(prev => !prev)}
                />
                <StyledToggle
                    label="Neutral"
                    selected={saveNeutral}
                    selectedCust={selectedColors}
                    deselectedCust={deselectedColors}
                    onClick={() => setSaveNeutral(prev => !prev)}
                />
                <StyledToggle
                    label="Environ."
                    selected={saveEnv}
                    selectedCust={selectedColors}
                    deselectedCust={deselectedColors}
                    onClick={() => setSaveEnv(prev => !prev)}
                />
            </ButtonRow>
            <Box>
                <LabelText>The graveyard is excluded by default.</LabelText>
                <ButtonRow>
                    <StyledToggle
                        label="Graveyard"
                        selected={saveGrave}
                        selectedCust={selectedColors}
                        deselectedCust={deselectedColors}
                        onClick={() => setSaveGrave(prev => !prev)}
                    />
                </ButtonRow>
            </Box>
            <Box>
                <LabelText>Send all creatures to the graveyard</LabelText>
                <KillButton label="Kill All" onClick={handleKill} />
            </Box>
            <Box>
                <LabelText>Remove all creatures entirely</LabelText>
                <DeleteButton label="Delete All" onClick={handleDelete} />
            </Box>
        </Container>
    )
}

export default ClearInitModal;