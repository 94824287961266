import { IoMdTrash } from "react-icons/io";
import DsButton, { DsButtonProps } from "../DsButton/DsButton";
import myTheme from "../../../myTheme";
import { Box } from "@mui/material";
import { MdEdit } from "react-icons/md";


const EditButton = ({ label, ...props }: DsButtonProps) => {

    return (
        <DsButton
            label={label}
            buttonProps={{
                style: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    width: "30px",
                    height: "30px",
                    fontSize: "18px",
                    padding: "1px 0 0 1px",
                    backgroundColor: myTheme.primary,
                    color: myTheme.light,
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    border: "none"
                }
            }}
            icon={<MdEdit />}
            sx={{
                width: "min-content",
                boxSizing: "border-box"
            }}
            {...props}
        />
    );
}

export default EditButton;