import { useEffect, useState } from "react";
import { ReactComponent as InitiativeIcon } from "../../../assets/svg/InitiativeIcon.svg";
import { ReactComponent as CreatureIcon } from "../../../assets/svg/CreatureIcon.svg";
import { ReactComponent as SpellsIcon } from "../../../assets/svg/SpellsIcon.svg";
import { ReactComponent as ItemsIcon } from "../../../assets/svg/ItemsIcon.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/svg/SettingsIcon.svg";
import { styled } from "@mui/system";
import myTheme from "../../../myTheme";
import BorderButton from "../BorderButton/BorderButton";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectPath, setPath } from "../../../redux/slices/dungeonsuiteSlice";
import { NAV_PAGES } from "../../../constants/DS.constants";

const NavContainer = styled("div")({
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "7.75vh",
    backgroundColor: myTheme.offWhite,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "5px 15px",
    overflowY: "clip",
    gap: "10px"
})

const ButtonContainer = styled("div")({
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box"
})

export interface MobileNavBarProps {
    navigate: any
}

const MobileNavBar = ({ navigate }: MobileNavBarProps) => {

    const dispatch = useAppDispatch();
    const path = useAppSelector(selectPath);

    useEffect(() => {
        const p = window.location.pathname
        dispatch(setPath(p));
    }, []);

    const handleNav = (dest: string) => {
        navigate("/ds/" + dest);
        dispatch(setPath("/ds/" + dest));
    }

    return (
        <>
            {NAV_PAGES.includes(path) && (
                <NavContainer data-testid="bottomNav--mobile">
                    <ButtonContainer>
                        <BorderButton
                            id="initButton"
                            targetPath="/initTracker"
                            currentPath={path}
                            Icon={
                                <InitiativeIcon
                                    data-testid={"bottomNav__button--initiative"}
                                    height={"100%"}
                                    onClick={() => handleNav("initTracker")}
                                />
                            }
                        />
                    </ButtonContainer>
                    <ButtonContainer>
                        <BorderButton
                            id="creaturesButton"
                            targetPath="/creatures"
                            currentPath={path}
                            Icon={
                                <CreatureIcon
                                    data-testid={"bottomNav__button--creatures"}
                                    height={"100%"}
                                    onClick={() => handleNav("creatures")}
                                />
                            }
                        />
                    </ButtonContainer>
                    <ButtonContainer>
                        <BorderButton
                            id="spellsButton"
                            targetPath="/spells"
                            currentPath={path}
                            Icon={
                                <SpellsIcon
                                    data-testid={"bottomNav__button--spells"}
                                    height={"100%"}
                                    onClick={() => handleNav("spells")}
                                />
                            }
                        />
                    </ButtonContainer>
                    <ButtonContainer>
                        <BorderButton
                            id="itemsButton"
                            targetPath="/items"
                            currentPath={path}
                            Icon={
                                <ItemsIcon
                                    data-testid={"bottomNav__button--items"}
                                    height={"100%"}
                                    onClick={() => handleNav("items")}
                                />
                            }
                        />
                    </ButtonContainer>
                    <ButtonContainer>
                        <BorderButton
                            id="settingsButton"
                            targetPath="/settings"
                            currentPath={path}
                            Icon={
                                <SettingsIcon
                                    data-testid={"bottomNav__button--settings"}
                                    height={"100%"}
                                    onClick={() => handleNav("settings")}
                                />
                            }
                        />
                    </ButtonContainer>
                </NavContainer>
            )}
        </>
    );
}

export default MobileNavBar;