import { Box, styled } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import myTheme from "../../../../myTheme";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { setAddCreatureActive } from "../../../../redux/slices/modalSlice";
import { setSelectedCreature } from "../../../../redux/slices/creatureSlice";
import { FabProps } from "../FilterFab/FilterFab";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

const FabWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: "3vw",
    bottom: "10vh",
    backgroundColor: myTheme.itemYellow,
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
}))

export interface AddFabProps extends FabProps {
    setSelected?: ActionCreatorWithPayload<any, string>
}

const AddFab = ({ visibilityToggle, setSelected }: AddFabProps) => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        if (setSelected) {
            dispatch(setSelected(undefined));
        }
        dispatch(visibilityToggle(true));
    }

    return (
        <FabWrapper data-testid="addFab__wrapper" onClick={handleClick}>
            <AddIcon 
                sx={{
                    fontSize: "50px",
                    color: myTheme.light
                }}
            />
        </FabWrapper>
    )
}

export default AddFab;