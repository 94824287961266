import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/EncodeSans.ttf';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import myTheme from './myTheme';
import store from './redux/store';
import { GoogleOAuthProvider } from "@react-oauth/google";

require('react-web-vector-icons/fonts');

const theme = createTheme({
    palette: {
        primary: {
            main: myTheme.primary
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 420,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider
            clientId="151091443642-ho5bgo4cjt52ilr9tlerkltkt7g7s9v1.apps.googleusercontent.com"
        >
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ThemeProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
