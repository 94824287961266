import { IMagicItem } from "../types/MagicItemTypes";


export const getMagicItemTypeLine = (magicItem: IMagicItem | undefined) => {
    let typeLine = "";
    if (!magicItem) return typeLine;
    if (magicItem.item_type) {
        magicItem.item_type.forEach((t, i) => {
            if (i !== 0) {
                typeLine += " " + t.name;
            } else {
                typeLine += t.name
            }
        });
    }
    if (magicItem.item_type && magicItem.item_type.length > 0) {
        typeLine += ", "
    }
    if (magicItem.rarity) {
        typeLine += magicItem.rarity.name + " ";
    }
    if (magicItem.requires_attunement) {
        typeLine += "(requires attunement)";
    }
    return typeLine;
}