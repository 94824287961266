import { Box, styled } from "@mui/material";
import myTheme from "../../../myTheme";
import { CSSProperties } from "react";

export const ListItemContainer = styled(Box)({
    width: "100%",
    boxSizing: "content-box",
    borderBottom: "3px solid " + myTheme.darkest
});

export const ListItemInnerContainer = styled(Box)({
    padding: "5px 10px"
});

export interface ListItemProps {
    children: JSX.Element;
    onClick?: () => void;
    style: CSSProperties;
}

const ListItem = ({ onClick, children, style }: ListItemProps) => {
    return (
        <ListItemContainer
            onClick={onClick}
            style={style}
        >
            <ListItemInnerContainer>
                {children}
            </ListItemInnerContainer>
        </ListItemContainer>
    );
};

export default ListItem;