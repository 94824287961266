import { Box, Grid, Typography } from "@mui/material";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import { StatLabel } from "../../modals/ViewCreatureModal/ViewCreatureModal";
import DsDropdown from "../DsDropdown/DsDropdown";
import { getSpellLevelDataSet } from "../../../constants/SpellLevel.constants";

export interface DsDropdownRangeProps {
    label: string;
    lowValue: any;
    highValue: any;
    setLowValue: any;
    setHighValue: any;
    data: any[];
    attribute: string;
}

const DsDropdownRange = ({
    label,
    lowValue,
    highValue,
    setLowValue,
    setHighValue,
    data,
    attribute
}: DsDropdownRangeProps) => {
    return (
        <Wrapper>
            <StatLabel>{label}</StatLabel>
            <Grid container columnSpacing={"10px"}>
                <Grid item xs={6}>
                    <DsDropdown xs={12}
                        name="min"
                        label="Min"
                        value={lowValue}
                        setValue={setLowValue}
                        data={data}
                        attribute={attribute}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DsDropdown xs={12}
                        name="max"
                        label="Max"
                        value={highValue}
                        setValue={setHighValue}
                        data={data}
                        attribute={attribute}
                    />
                </Grid>

            </Grid>
        </Wrapper>
    );
};

export default DsDropdownRange;