import { Box, Typography, styled } from "@mui/material";
import myTheme from "../../../myTheme";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectInitOrder } from "../../../redux/slices/initSlice";
import Status from "../../../constants/CreatureStatus";
import Teams from "../../../constants/Teams.constants";
import { getXpByCr } from "../../../constants/Cr.constants";
import { useEffect, useState } from "react";
import { IInitItem } from "../../../types/InitiativeTypes";
import calculateXp, { XpItem } from "../../../helper/initHelper/totalXp";

const LineItemContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    // gap: "5px"
})

const LineItemRow = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px"
})

const LineItemTitle = styled(Typography)({
    fontSize: "18px",
    fontWeight: "bold",
    color: myTheme.offWhite
})

const LineItemText = styled(Typography)({
    fontSize: "18px",
    color: myTheme.offWhite
})

const LineItemXp = styled(Typography)({
    fontSize: "18px",
    color: myTheme.offWhite
})

const XpModal = () => {

    const [subtotal, setSubtotal] = useState<number>(0);
    const [dead, setDead] = useState<XpItem[]>([]);
    const [nPlayers, setNPlayers] = useState<number>(0);

    const initOrder = useAppSelector(selectInitOrder);

    useEffect(() => {
        const [total, nPlayers, items] = calculateXp(initOrder);
        setDead(items);
        setSubtotal(total);
        setNPlayers(nPlayers);
    }, [initOrder]);

    return (
        <>
            <Box
                sx={{
                    borderBottom: "1px solid " + myTheme.offWhite
                }}
            >
                {dead.map((item, i) => (
                    <LineItemContainer key={i}>
                        <LineItemRow>
                            <LineItemTitle>{item.name}</LineItemTitle>
                            <LineItemXp>{item.xp * item.qty}</LineItemXp>
                        </LineItemRow>
                        <LineItemRow>
                            <LineItemText>Qty {item.qty} at {item.xp} each</LineItemText>
                        </LineItemRow>
                    </LineItemContainer>
                ))}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                }}
            >
                <Box
                    sx={{
                        minWidth: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}
                >
                    {/* <LineItemRow>
                        <LineItemText>Subtotal:</LineItemText>
                        <LineItemText>{subtotal}</LineItemText>
                    </LineItemRow> */}
                    <LineItemRow>
                        <LineItemText>Total:</LineItemText>
                        <LineItemText>{subtotal}</LineItemText>
                    </LineItemRow>
                    <LineItemRow>
                        <LineItemText>Players:</LineItemText>
                        <LineItemText>{nPlayers}</LineItemText>
                    </LineItemRow>
                    <LineItemRow>
                        <LineItemText>XP / Player:</LineItemText>
                        <LineItemText>{nPlayers > 0 ? subtotal / nPlayers : "---"}</LineItemText>
                    </LineItemRow>
                </Box>
            </Box>
        </>
    )
}

export default XpModal;