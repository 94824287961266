import axios from "axios";
import { API_BASE_URL } from "./api.constants";
import { getConfig } from "./api.helper";
import { ICreature } from "../types/CreatureTypes";
import { IStatblockItems } from "../types/StatblockTypes";

export const getAllCreatures = async (authToken: string) => {
    try {
        const res = await axios.get<{ data: ICreature[] }>(
            API_BASE_URL + "creature",
            getConfig(authToken)
        );
        if (res.status === 200) {
            return res.data.data;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error getting all creatures", e);
    }
};

export const getAllCreatureStatBlockItems = async (authToken: string) => {
    try {
        const res = await axios.get<{ data: IStatblockItems }>(
            API_BASE_URL + "creature/statBlockItems",
            getConfig(authToken)
        );
        if (res.status === 200) {
            return res.data.data;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error getting all creature stat block items", e);
    }
};