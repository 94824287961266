
export const sortByName = function (list: any[]) {
    const returnList = list.sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });
    return returnList;
};

export const sortByLevel = (list: any[]) => {
    const returnList = list.sort((a, b) => {
        if (a.level < b.level) return -1;
        else if (a.level > b.level) return 1;
        else return 0;
    })
    return returnList;
}

export const sortByCR = (list: any[]) => {
    const returnList = list.sort((a, b) => {
        if (a.challenge_rating < b.challenge_rating) return -1;
        else if (a.challenge_rating > b.challenge_rating) return 1;
        else return 0;
    })
    return returnList;
}

export const sortByRarity = (list: any[]) => {
    const returnList = list.sort((a, b) => {
        try {
            if (!RarityMap.get(b.rarity.name)) {
                return -1;
            }
            if (RarityMap.get(a.rarity.name) < RarityMap.get(b.rarity.name)) {
                return -1;
            } else if (RarityMap.get(a.rarity.name) > RarityMap.get(b.rarity.name)) {
                return 1;
            } else {
                return 0;
            }
        } catch (e) {
            console.log('err', e);
            return 1;
        }
    });
    return returnList;
};

const RarityMap = new Map();
RarityMap.set("common", 1);
RarityMap.set("uncommon", 2);
RarityMap.set("rare", 3);
RarityMap.set("very rare", 4);
RarityMap.set("legendary", 5);
RarityMap.set("artifact", 6);