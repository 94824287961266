import { Box, styled, Theme, Typography } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validateInt } from "../../../helper/validation/inputValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { editInitItem, selectSelectedInitItem } from "../../../redux/slices/initSlice";
import { selectInitHpActive, setInitHpActive } from "../../../redux/slices/modalSlice";
import HealthBar from "../../dataDisplay/HealthBar/HealthBar";
import DsButton from "../../input/DsButton/DsButton";
import { BiSave } from "react-icons/bi";
import TagScrollView, { Tag } from "../../dataDisplay/TagScrollView/TagScrollView";
import { IDamageType } from "../../../types/CreatureTypes";

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold"
})

const HealthBarWrapper = styled(Box)({
    marginBottom: "15px"
})

const RowContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "15px"
})

const ColContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    justifyContent: "center"
})

type StyledButtonProps = React.HTMLProps<HTMLButtonElement> & MUIStyledCommonProps<Theme> & { theme: Theme; } & { tempActive: boolean; }

const StyledButton = styled("button")(({ theme, tempActive }: StyledButtonProps) => {
    const color = tempActive ? myTheme.primary : myTheme.medGray;
    return ({
        padding: "8px",
        fontSize: "24px",
        backgroundColor: myTheme.medGray,
        borderRadius: "5px",
        border: "3px solid " + color
    })
})

const StyledInput = styled("input")({
    width: "100%",
    backgroundColor: myTheme.bg,
    border: "none",
    outline: "none",
    textAlign: "center",
    fontSize: "36px"
});

const InitHpModal = () => {

    const [modalHp, setModalHp] = useState(1);
    const [textValue, setTextValue] = useState('0')
    const [textValueTemp, setTextValueTemp] = useState('0')
    const [modalTempHp, setModalTempHp] = useState(0)
    const [tempActive, setTempActive] = useState(false)
    const [loaded, setLoaded] = useState(false);

    const modalVisible = useSelector(selectInitHpActive);
    const item = useAppSelector(selectSelectedInitItem);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!item) return;
        setModalHp(item.hp);
        setModalTempHp(item.tempHp ? item.tempHp : 0)
        setLoaded(true);
    }, [modalVisible]);

    const modifyHP = (amount: number) => {
        if (!item) return

        if (tempActive) {
            const newTempStr = textValueTemp.replace(/[^0-9-]/g, '');
            const n = parseInt(newTempStr);

            let newTemp = amount

            newTemp += n

            if (newTemp < -1 * item.tempHp) newTemp = -1 * item.tempHp;

            setTextValueTemp(newTemp + '')

            setModalTempHp((prev) => {
                let newTemp = prev + amount
                if (newTemp < 0) newTemp = 0
                return newTemp
            })
        } else {
            const newStr = textValue.replace(/[^0-9-]/g, '');
            const n = parseInt(newStr);

            let newHP = amount

            newHP += n

            if (newHP < -1 * item.hp) newHP = -1 * item.hp
            if (newHP > item.maxHp - item.hp) newHP = item.maxHp - item.hp

            setTextValue(newHP + '')

            setModalHp(prev => {
                let newHP = prev + amount
                if (newHP < 0) newHP = 0
                if (newHP > item.maxHp) newHP = item.maxHp
                return newHP
            })
        }
    }

    const getHpColor = () => {
        const newText = textValue.replace(/[^0-9-]/g, '')
        const n = parseInt(newText);
        if (tempActive) return myTheme.primary
        if (n === 0) return myTheme.light
        if (n < 0) return myTheme.healthRed
        if (n > 0) return myTheme.healthGreen
    }

    const handleSubmit = () => {
        if (!item) return;
        const newItem = { ...item }
        newItem.hp = modalHp
        newItem.tempHp = modalTempHp
        dispatch(editInitItem(newItem))
        dispatch(setInitHpActive(false))
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {item && loaded && (
                <>
                    <Heading>{item.name}</Heading>
                    <HealthBarWrapper>
                        <HealthBar
                            hp={modalHp}
                            maxHp={item.maxHp}
                            tempHp={modalTempHp}
                        />
                        {item.statblock?.damage_vulnerabilities && item.statblock.damage_vulnerabilities.length > 0 && (
                            <TagScrollView
                                label="Vulnerabilities"
                                list={item.statblock.damage_vulnerabilities}
                                RenderItem={({ item }: { item: IDamageType }) => (
                                    <Tag>{item.name}</Tag>
                                )}
                            />
                        )}
                        {item.statblock?.damage_resistances && item.statblock.damage_resistances.length > 0 && (
                            <TagScrollView
                                label="Resistances"
                                list={item.statblock.damage_resistances}
                                RenderItem={({ item }: { item: IDamageType }) => (
                                    <Tag>{item.name}</Tag>
                                )}
                            />
                        )}
                        {item.statblock?.damage_immunities && item.statblock.damage_immunities.length > 0 && (
                        <TagScrollView
                            label="Immunities"
                            list={item.statblock.damage_immunities}
                            RenderItem={({ item }: { item: IDamageType }) => (
                                <Tag>{item.name}</Tag>
                            )}
                        />
                    )}
                    </HealthBarWrapper>
                    <RowContainer>
                        <ColContainer>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(-1)}>-1</StyledButton>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(-5)}>-5</StyledButton>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(-10)}>-10</StyledButton>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(-20)}>-20</StyledButton>
                        </ColContainer>

                        <ColContainer sx={{ flexGrow: 1 }}>
                            <StyledInput
                                value={tempActive ? textValueTemp : textValue}
                                onChange={() => { }}
                                style={{
                                    color: getHpColor()
                                }}
                            // onChange={handleChangeText}
                            />
                        </ColContainer>

                        <ColContainer>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(1)}>+1</StyledButton>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(5)}>+5</StyledButton>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(10)}>+10</StyledButton>
                            <StyledButton tempActive={tempActive} onClick={() => modifyHP(20)}>+20</StyledButton>
                        </ColContainer>
                    </RowContainer>
                    <ColContainer sx={{ marginTop: "15px" }}>
                        <DsButton label="Temp HP" onClick={() => setTempActive(prev => !prev)} />
                        <DsButton icon={<BiSave fontSize={"20px"} />} label="Submit" onClick={handleSubmit} />
                    </ColContainer>
                </>
            )}
        </Box>
    )
}

export default InitHpModal;