import { ISimpleSchema, ISource } from "./CreatureTypes";
import { IFriend } from "./FriendTypes";

export interface IMagicItem {
    _id?: string;
    name: string;
    item_type?: ISimpleSchema[];
    requires_attunement: boolean;
    rarity?: ISimpleSchema;
    description: string;
    source?: ISimpleSchema | string;
    visibility?: string;
    createdBy?: string;
    sharedWith?: IFriend[];
}

export const MagicItemSortByOptions = {
    name: "Name",
    rarity: "Rarity"
}