import { IoMdTrash } from "react-icons/io";
import DsButton, { DsButtonProps } from "../DsButton/DsButton";
import myTheme from "../../../myTheme";


const DeleteButton = ({ label = "Delete", ...props }: DsButtonProps) => {

    return (
        <DsButton
            icon={<IoMdTrash />}
            label={label}
            buttonProps={{
                style: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    width: "100%",
                    padding: "8px 15px",
                    fontSize: "32px",
                    backgroundColor: myTheme.healthRed,
                    color: myTheme.medGray,
                    fontWeight: "bold",
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    border: "none"
                }
            }}
            {...props}
        />
    );
}

export default DeleteButton;