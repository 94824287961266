import { ISimpleSchema } from "../../types/CreatureTypes";

export const simpleSchemaListIncludes = (list: ISimpleSchema[], compare: ISimpleSchema | undefined) => {
    let res = false;
    if (compare) {
        list.forEach(li => {
            if (li._id === compare._id) {
                res = true;
            }
        });
    }

    return res;
}

export const simpleSchemaListIncludesName = (list: ISimpleSchema[] | undefined, compare: string) => {
    let res = false;
    if (list) {
        list.forEach(li => {
            if (li.name.toUpperCase().includes(compare.toUpperCase())) {
                res = true;
            }
        });
    }

    return res;
}

export const compareSimpleSchemaLists = (listA: ISimpleSchema[], listB: ISimpleSchema[] | undefined) => {
    let res = false;
    if (listA && listB) {
        listA.forEach(li1 => {
            listB.forEach(li2 => {
                if (li1._id === li2._id) {
                    res = true;
                }
            })
        })
    }
    return res;
}