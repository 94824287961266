import { Box, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { IAction } from "../../../types/CreatureTypes";
import { StatLabel } from "../../modals/ViewCreatureModal/ViewCreatureModal";
import myTheme from "../../../myTheme";
import { getActionHeaderText } from "../../../helper/creatureHelper/creatureHelper";

export const Wrapper = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 0",
    overflow: "hidden"
});

const ListContainer = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px"
});

const DropdownHead = styled(Typography)({
    width: "100%",
    padding: "5px",
    color: myTheme.light,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: myTheme.primary,
    borderRadius: "5px",
    boxSizing: "border-box"
});

const DropdownHeadActive = styled(Box)({
    width: "100%",
    padding: "5px",
    color: myTheme.light,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: myTheme.primary,
    borderRadius: "5px",
    boxSizing: "border-box",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px"
});

const DropdownBody = styled(Box)({
    width: "100%",
    borderColor: myTheme.primary,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    border: "2px solid " + myTheme.primary,
    borderTopWidth: "0",
    color: myTheme.light,
    textAlign: "left",
    padding: "5px 10px",
    boxSizing: "border-box",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
});

export interface RenderItemProps {
    item: IAction;
}

const DefaultRenderItem = ({ item }: RenderItemProps) => {
    const [active, setActive] = useState<boolean>(false);

    const handleClick = () => {
        setActive(prev => !prev);
    };

    return (
        <Box>
            {active ? (
                <DropdownHeadActive onClick={handleClick}>{getActionHeaderText(item)}</DropdownHeadActive>
            ) : (
                <DropdownHead onClick={handleClick}>{getActionHeaderText(item)}</DropdownHead>
            )}
            {active && (
                <DropdownBody>{item.desc + ""}</DropdownBody>
            )}
        </Box>
    );
};

export interface AccordionListProps {
    label: string;
    list: IAction[];
}

const AccordionList = ({
    label,
    list
}: AccordionListProps) => {

    return (
        <Wrapper>
            <StatLabel>{label}</StatLabel>
            <ListContainer>
                {list.map(sa => {
                    return (
                        <DefaultRenderItem item={sa} key={sa._id} />
                    )
                })}
            </ListContainer>
        </Wrapper>
    );
};

export default AccordionList;