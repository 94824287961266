import { Grid, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { validatePosInt } from "../../../helper/validation/inputValidation";
import myTheme from "../../../myTheme";
import DsInput from "../../input/DsInput/DsInput";

const InitLabel = styled(Typography)({
    color: myTheme.medGray,
    fontSize: "24px",
    fontWeight: "bold"
})

const RerollInitEntry = ({ item, index, setInits }: any) => {

    const [init, setInit] = useState("");

    useEffect(() => {
        setInits((prev: string[]) => {
            const newArr = [...prev]
            newArr[index] = init
            return newArr
        })
    }, [init])

    return (
        <>
            <Grid item xs={8}>
                <InitLabel>{item.name}</InitLabel>
            </Grid>
            <Grid item xs={4}>
                <DsInput value={init} setValue={setInit} validate={validatePosInt} />
            </Grid>
        </>
    )
}

export default RerollInitEntry;