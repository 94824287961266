import { Box, styled } from "@mui/material";
import myTheme from "../../../../myTheme";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { setClearInitActive, setRerollInitActive } from "../../../../redux/slices/modalSlice";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { FaFilter } from "react-icons/fa";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

const FabWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: "3vw",
    bottom: "19vh",
    backgroundColor: myTheme.green,
    width: "60px",
    height: "60px",
    paddingTop: "5px",
    boxSizing: "border-box",
    borderRadius: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
}))

export interface FabProps {
    visibilityToggle: ActionCreatorWithPayload<boolean, string>;
}

const FilterFab = ({ visibilityToggle }: FabProps) => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(visibilityToggle(true));
    }

    return (
        <FabWrapper data-testid="filterFab__wrapper" onClick={handleClick}>
            <FaFilter color={myTheme.light} size={"30px"}  />
        </FabWrapper>
    )
}

export default FilterFab;