import { Box, Typography, styled } from "@mui/material";
import { ICreature } from "../../../types/CreatureTypes";
import ListItem from "../../dataDisplay/ListItem/ListItem";
import { NameText } from "../../inititivePageComponents/InitLi/InitLi.styled";
import myTheme from "../../../myTheme";
import { parseNumericCR } from "../../../constants/Cr.constants";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setSelectedCreature } from "../../../redux/slices/creatureSlice";
import { CSSProperties } from "react";
import { setViewCreatureActive, setViewMagicItemActive } from "../../../redux/slices/modalSlice";
import { IMagicItem } from "../../../types/MagicItemTypes";
import { setSelectedMagicItem } from "../../../redux/slices/magicItemSlice";
import { getMagicItemTypeLine } from "../../../helper/magicItemHelper";

const CreatureLiWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
});

const CreatureTextContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 5,
    whiteSpace: "nowrap",
    width: "30%"
});

const CreatureCRContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "30%",
    whiteSpace: "nowrap"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light,
    paddingLeft: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    overflowX: "clip",
    textOverflow: "ellipsis",
});

export interface MagicItemLiProps {
    magicItem: IMagicItem,
    style: CSSProperties
}

const MagicItemLi = ({ magicItem, style }: MagicItemLiProps) => {

    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(setSelectedMagicItem(magicItem));
        dispatch(setViewMagicItemActive(true));
    }

    return (
        <ListItem onClick={handleClick} style={style}>
            <CreatureLiWrapper>
                <CreatureTextContainer>
                    <NameText>{magicItem.name}</NameText>
                    <SubText>{getMagicItemTypeLine(magicItem)}</SubText>
                </CreatureTextContainer>
            </CreatureLiWrapper>
        </ListItem>
    );
};

export default MagicItemLi;