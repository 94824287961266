import { Box, Grid, Typography, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setCopyCreature, setSelectedCreature } from "../../../redux/slices/creatureSlice";
import myTheme from "../../../myTheme";
import AcIcon from "../../icons/AcIcon/AcIcon";
import { getXpByCr, parseNumericCR } from "../../../constants/Cr.constants";
import { FaRegHeart } from "react-icons/fa";
import { getAbilityMod } from "../../../helper/creatureHelper/creatureHelper";
import AccordionList, { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import TagScrollView, { Tag } from "../../dataDisplay/TagScrollView/TagScrollView";
import { ICreature, IDamageType, ILanguage, ISave, ISenseStat, ISkill } from "../../../types/CreatureTypes";
import { ICondition } from "../../../types/StatblockTypes";
import { useState } from "react";
import { selectAuthToken, selectUser } from "../../../redux/slices/userSlice";
import { deleteCreature } from "../../../api/deleteData";
import { setAddCreatureActive, setViewCreatureActive } from "../../../redux/slices/modalSlice";
import DeleteButtonMd from "../../input/buttons/DeleteButtonMd";
import EditButtonMd from "../../input/buttons/EditButtonMd";
import CopyButtonMd from "../../input/buttons/CopyButtonMd";
import DsButton from "../../input/DsButton/DsButton";
import ShareModal from "../ShareModal/ShareModal";
import { updateCreature } from "../../../api/updateData";

export const ContentGrid = styled(Grid)({
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center"
});

const StatSection = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: 'space-evenly',
    alignItems: "flex-end",
    gap: "10px",
    padding: "0 15px 17px 15px"
});

const AbilitySection = styled(Grid)(({
    marginTop: "10px",
    width: "100%",
    border: `2px solid ${myTheme.primary}`,
    borderRadius: "5px",
    padding: "10px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
}));

export const AcWrapper = styled(Box)(({ theme }) => ({
    width: "40px",
    height: "60px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: "relative"
}))

const StatBubbleSmall = styled(Box)({
    width: "40px",
    height: "40px",
    backgroundColor: myTheme.primary,
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
});

export const StatBubbleTextSmall = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    textAlignVertical: 'center',
    textAlign: 'center',
    fontSize: "20px",
    color: myTheme.light,
    fontWeight: 'bold',
    paddingBottom: "4px"
}))

const StatWrapper = styled(Box)({

});

export const StatLabel = styled(Typography)({
    color: myTheme.light,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center"
});

const ItalicLabel = styled(Typography)({
    color: myTheme.light,
    fontSize: "14px",
    fontStyle: "italic",
    textAlign: "center"
});

const StatText = styled(Typography)({
    color: myTheme.light,
    fontSize: "14px",
    textAlign: "center"
});

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light
});

interface ViewCreatureModalProps {
    disableEditing?: boolean,
    creature?: ICreature;
}

const ViewCreatureModal = ({ disableEditing, creature }: ViewCreatureModalProps) => {
    const [shareOpen, setShareOpen] = useState<boolean>(false);
    
    const user = useAppSelector(selectUser);
    const authToken = useAppSelector(selectAuthToken);

    const dispatch = useAppDispatch();

    const getTypeLine = () => {
        let typeLine = ''
        const c = creature;
        if (!c) return "";

        typeLine += c.size ? c.size.name : ''
        if (c.creatureType) {
            c.creatureType.forEach((t, i) => {
                typeLine += ' ' + t.name
            })
        }
        typeLine += ', ' + c.alignment
        return typeLine;
    }

    const handleEdit = () => {
        if (creature) {
            dispatch(setSelectedCreature(creature));
            dispatch(setAddCreatureActive(true));
            dispatch(setViewCreatureActive(false));
        }
    };

    const handleCopy = () => {
        if (creature) {
            dispatch(setSelectedCreature(creature));
            dispatch(setCopyCreature(true));
            dispatch(setAddCreatureActive(true));
            dispatch(setViewCreatureActive(false));
        }
    }

    const handleDelete = async () => {
        await deleteCreature(creature, authToken);
        dispatch(setViewCreatureActive(false));
    };

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: "15px"
            }}
        >
            <ContentGrid container>
                <Grid item xs={12}>
                    <Heading>{creature?.name}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <SubText sx={{ textAlign: "center" }}>{getTypeLine()}</SubText>
                </Grid>

                <Grid item container xs={12} direction="row">
                    <Grid item xs={6}
                        sx={{
                            borderRight: `2px solid ${myTheme.primary}`
                        }}
                    >
                        <StatSection>
                            <AcWrapper>
                                <AcIcon strokeWidth={20} fill={myTheme.primary} />
                                <StatBubbleTextSmall>{creature?.armor_class}</StatBubbleTextSmall>
                            </AcWrapper>
                            <StatBubbleSmall>
                                <FaRegHeart color={myTheme.darkest} fontSize={"30px"} />
                                <StatBubbleTextSmall sx={{ padding: "1px 0 0 0" }}>{creature?.hit_points}</StatBubbleTextSmall>
                            </StatBubbleSmall>
                            <StatWrapper>
                                <StatLabel>CR</StatLabel>
                                <StatText>{parseNumericCR(creature?.challenge_rating)}</StatText>
                            </StatWrapper>
                            <StatWrapper>
                                <StatLabel>XP</StatLabel>
                                <StatText>{getXpByCr(creature?.challenge_rating)}</StatText>
                            </StatWrapper>
                        </StatSection>
                    </Grid>
                    <Grid item xs={6}>
                        <StatSection sx={{ paddingTop: "17px" }}>
                            {creature?.speed && creature.speed.length > 0
                                && creature.speed.map((speed, i) => (
                                    <StatWrapper key={i}>
                                        <StatLabel>{speed.movement.name}</StatLabel>
                                        <StatText>{speed.speed}</StatText>
                                    </StatWrapper>
                                ))
                            }
                        </StatSection>
                    </Grid>
                </Grid>

                <AbilitySection item container xs={12} direction="row">
                    <StatWrapper>
                        <StatLabel>Str</StatLabel>
                        <StatText>{creature?.strength}</StatText>
                        <StatText>{getAbilityMod(creature?.strength)}</StatText>
                    </StatWrapper>
                    <StatWrapper>
                        <StatLabel>Dex</StatLabel>
                        <StatText>{creature?.dexterity}</StatText>
                        <StatText>{getAbilityMod(creature?.dexterity)}</StatText>
                    </StatWrapper>
                    <StatWrapper>
                        <StatLabel>Con</StatLabel>
                        <StatText>{creature?.constitution}</StatText>
                        <StatText>{getAbilityMod(creature?.constitution)}</StatText>
                    </StatWrapper>
                    <StatWrapper>
                        <StatLabel>Int</StatLabel>
                        <StatText>{creature?.intelligence}</StatText>
                        <StatText>{getAbilityMod(creature?.intelligence)}</StatText>
                    </StatWrapper>
                    <StatWrapper>
                        <StatLabel>Wis</StatLabel>
                        <StatText>{creature?.wisdom}</StatText>
                        <StatText>{getAbilityMod(creature?.wisdom)}</StatText>
                    </StatWrapper>
                    <StatWrapper>
                        <StatLabel>Cha</StatLabel>
                        <StatText>{creature?.charisma}</StatText>
                        <StatText>{getAbilityMod(creature?.charisma)}</StatText>
                    </StatWrapper>
                </AbilitySection>

                {creature?.special_abilities && creature.special_abilities.length > 0 && (
                    <AccordionList
                        label="Abilities"
                        list={creature.special_abilities}
                    />
                )}

                {creature?.actions && creature.actions.length > 0 && (
                    <AccordionList
                        label="Actions"
                        list={creature.actions}
                    />
                )}

                {creature?.legendary_actions && creature.legendary_actions.length > 0 && (
                    <AccordionList
                        label="Legendary Actions"
                        list={creature.legendary_actions}
                    />
                )}

                {creature?.saves && creature.saves.length > 0 && (
                    <TagScrollView
                        label="Saving Throws"
                        list={creature.saves}
                        RenderItem={({ item }: { item: ISave }) => (
                            <Tag>{item.abilityScore.toUpperCase()} {item.value >= 0 ? "+" : ""}{item.value}</Tag>
                        )}
                    />
                )}

                {creature?.skills && creature.skills.length > 0 && (
                    <TagScrollView
                        label="Skills"
                        list={creature.skills}
                        RenderItem={({ item }: { item: ISkill }) => (
                            <Tag>{item.skill.name} {item.value >= 0 ? "+" : ""}{item.value}</Tag>
                        )}
                    />
                )}

                {creature?.damage_vulnerabilities && creature.damage_vulnerabilities.length > 0 && (
                    <TagScrollView
                        label="Vulnerabilities"
                        list={creature.damage_vulnerabilities}
                        RenderItem={({ item }: { item: IDamageType }) => (
                            <Tag>{item.name}</Tag>
                        )}
                    />
                )}

                {creature?.damage_resistances && creature.damage_resistances.length > 0 && (
                    <TagScrollView
                        label="Resistances"
                        list={creature.damage_resistances}
                        RenderItem={({ item }: { item: IDamageType }) => (
                            <Tag>{item.name}</Tag>
                        )}
                    />
                )}

                {creature?.damage_immunities && creature.damage_immunities.length > 0 && (
                    <TagScrollView
                        label="Immunities"
                        list={creature.damage_immunities}
                        RenderItem={({ item }: { item: IDamageType }) => (
                            <Tag>{item.name}</Tag>
                        )}
                    />
                )}

                {creature?.condition_immunities && creature.condition_immunities.length > 0 && (
                    <TagScrollView
                        label="Condition Immunities"
                        list={creature.condition_immunities}
                        RenderItem={({ item }: { item: ICondition }) => (
                            <Tag>{item.name}</Tag>
                        )}
                    />
                )}

                {creature?.senses && creature.senses.length > 0 && (
                    <TagScrollView
                        label="Senses"
                        list={[...creature.senses, { _id: "pp", range: creature.pSenses ? creature.pSenses.pp : 10, sense: { name: "PP", _id: "", visibility: "", createdBy: "", createdAt: "", "updatedAt": "", __v: 0, source: "" } }]}
                        RenderItem={({ item }: { item: ISenseStat }) => (
                            <Tag>{item.sense.name} {item.range}</Tag>
                        )}
                    />
                )}

                {creature?.languages && creature.languages.length > 0 && (
                    <TagScrollView
                        label="Languages"
                        list={creature.languages}
                        RenderItem={({ item }: { item: ILanguage }) => (
                            <Tag>{item.name}</Tag>
                        )}
                    />
                )}

                <Wrapper>
                    {/* @ts-ignore */}
                    <ItalicLabel>{creature?.source.name + ""}</ItalicLabel>
                </Wrapper>

                <Wrapper sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px"
                }}>
                    <CopyButtonMd onClick={handleCopy} />
                </Wrapper>

                {!disableEditing && (<>
                    {creature?.createdBy === user?._id && (
                        <Wrapper sx={{ gap: "5px" }}>
                            <DsButton
                                label="Share"
                                onClick={() => setShareOpen(true)}
                                buttonProps={{ style: { backgroundColor: myTheme.primary, color: myTheme.light } }}
                            />
                            <Wrapper sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px"
                            }}>
                                <EditButtonMd onClick={handleEdit} />
                                <DeleteButtonMd onClick={handleDelete} confirmDelete />
                            </Wrapper>
                        </Wrapper>
                    )}
                </>)}

                <ShareModal
                    open={shareOpen}
                    setOpen={setShareOpen}
                    onSubmit={async (list) => {
                        await updateCreature({
                            ...creature,
                            sharedWith: list
                        });
                        setShareOpen(false);
                    }}
                    sharedWith={(creature && creature.sharedWith) ? creature.sharedWith : []}
                />
            </ContentGrid>
        </Box>
    );
};

export default ViewCreatureModal;