import { Box, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { editInitItem, selectSelectedInitItem } from "../../../redux/slices/initSlice";
import { selectDeathSavesActive, setDeathSavesActive } from "../../../redux/slices/modalSlice";
import { ISaves } from "../../../types/InitiativeTypes";
import DeathSaves from "../../dataDisplay/DeathSaves/DeathSaves";
import DsButton from "../../input/DsButton/DsButton";
import { BiSave } from "react-icons/bi";

const SuccessButton = styled("button")({
    width: "100%",
    padding: "8px",
    fontSize: "12px",
    backgroundColor: myTheme.healthGreen,
    borderRadius: "5px",
    boxSizing: "border-box",
    border: "none"
})

const FailButton = styled("button")({
    width: "100%",
    padding: "8px",
    fontSize: "12px",
    backgroundColor: myTheme.healthRed,
    borderRadius: "5px",
    border: "none"
})

const ClearButton = styled("button")({
    width: "100%",
    padding: "8px",
    fontSize: "12px",
    backgroundColor: myTheme.medGray,
    borderRadius: "5px",
    border: "none"
})

const defaultSaves: ISaves = {
    success: 0,
    fail: 0
}

const DeathSavesModal = () => {

    const [tempSaves, setTempSaves] = useState<ISaves>(defaultSaves);

    const modalVisible = useAppSelector(selectDeathSavesActive);
    const item = useAppSelector(selectSelectedInitItem);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!item) return;
        setTempSaves({
            success: item.saves.success,
            fail: item.saves.fail
        })
    }, [modalVisible, item]);

    const handleSave = (val: string) => {
        setTempSaves(prev => {
            let newSuccess = prev.success
            let newFail = prev.fail

            if (val === 'success' && newSuccess <= 3) newSuccess++
            if (val === 'fail' && newFail <= 3) newFail++
            if (val === 'clear') {
                newSuccess = 0
                newFail = 0
            }
            const newSaves = {
                success: newSuccess,
                fail: newFail
            }
            return (newSaves)
        })
    }

    const handleDone = () => {
        if (!item) return;
        const newItem = { ...item }
        newItem.saves = tempSaves
        dispatch(editInitItem(newItem));
        dispatch(setDeathSavesActive(false));
    }

    return (
        <Box sx={{
            width: "100%",
            boxSizing: "border-box"
        }}>
            <DeathSaves saves={tempSaves} size={30} />
            <Grid container columnSpacing={"10px"} sx={{ boxSizing: "border-box", marginTop: "20px" }}>
                <Grid item xs={4}>
                    <FailButton onClick={() => handleSave("fail")}>Fail</FailButton>
                </Grid>
                <Grid item xs={4}>
                    <ClearButton onClick={() => handleSave("clear")}>Clear</ClearButton>
                </Grid>
                <Grid item xs={4}>
                    <SuccessButton onClick={() => handleSave("success")}>Success</SuccessButton>
                </Grid>
            </Grid>
            <DsButton icon={<BiSave fontSize={"20px"} />}  label="Done" onClick={handleDone} sx={{ marginTop: "20px" }} />
        </Box>
    )
}

export default DeathSavesModal;