import { IoMdTrash } from "react-icons/io";
import DsButton, { DsButtonProps } from "../DsButton/DsButton";
import myTheme from "../../../myTheme";
import { useState } from "react";
import { Box, Modal } from "@mui/material";
import { LabelText, ModalContentContainer, ModalInnerContainer, ModalOuterContainer, ModalTitle } from "../../modals/GenericModal/GenericModal";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";

export interface DeleteButtonProps extends DsButtonProps {
    confirmDelete?: boolean;
}

const DeleteButton = ({
    label = "Delete",
    confirmDelete,
    onClick,
    ...props
}: DeleteButtonProps) => {
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const handleDelete = (e: any) => {
        if (confirmDelete) {
            setConfirmOpen(true);
        } else {
            handleConfirm(e);
        }
    }

    const handleConfirm = (e: any) => {
        setConfirmOpen(false);
        if (onClick) {
            onClick(e);
        }
    }

    const handleCancel = () => {
        setConfirmOpen(false);
    };

    return (
        <>
            <DsButton
                icon={<IoMdTrash />}
                label={label}
                onClick={handleDelete}
                buttonProps={{
                    style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        width: "100%",
                        backgroundColor: myTheme.healthRed,
                        color: myTheme.medGray,
                        borderRadius: "5px",
                        boxSizing: "border-box",
                        border: "none"
                    }
                }}
                {...props}
            />
            <Modal
                open={confirmOpen}
                onClose={handleConfirm}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none"
                }}
                data-testid={`modal__confirm__modal`}
            >
                <ModalOuterContainer>
                    <ModalContentContainer>
                        <ModalTitle sx={{ padding: "10px 20px" }}>Are you sure you want to delete this item?</ModalTitle>
                        <ModalInnerContainer>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <LabelText>This operation can't be undone</LabelText>
                                <Wrapper sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "20px"
                                }}>
                                    <DsButton label="Yes, delete it" onClick={handleConfirm} />
                                    <DsButton label="No, save it" onClick={handleCancel} />
                                </Wrapper>
                            </Box>
                        </ModalInnerContainer>
                    </ModalContentContainer>
                </ModalOuterContainer>
            </Modal>
        </>
    );
}

export default DeleteButton;