import { Box } from "@mui/material";
import DsButton from "../DsButton/DsButton";
import { usePersistedState } from "../../../hooks/usePersistedState/usePersistedState";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setAuthToken, setUser } from "../../../redux/slices/userSlice";
import { setPath } from "../../../redux/slices/dungeonsuiteSlice";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../../constants/DS.constants";


const LoginButton = () => {
    const [, setDsToken] = usePersistedState<string>("dsToken", "");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogin = () => {
        setDsToken("");
        dispatch(setUser(undefined));
        dispatch(setAuthToken(""));

        dispatch(setPath(LOGIN_PATH));
        navigate(LOGIN_PATH);
    };

    return (
        <Box sx={{
            padding: "50px"
        }}>
            <DsButton
                label="Login"
                onClick={handleLogin}
            />
        </Box>
    );
};

export default LoginButton;