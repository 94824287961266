export const getSpellLevelDataSet = () => {
    const list = [];
    list.push({ level: "Cantrip" });
    for (let i = 1; i <= 30; i++) {
        list.push({ level: i + ""});
    }
    return list;
}

export const parseSpellLevel = (level: string) => {
    if (level === "Cantrip") {
        return 0;
    } else {
        return parseInt(level);
    }
}

export const parseNumericSpellLevel = (level: number) => {
    if (level === 0) {
        return "Cantrip";
    } else {
        return level + "";
    }
}