import { ISimpleSchema } from "../types/CreatureTypes";

export const ABILITY_SCORES: ISimpleSchema[] = [
    { _id: "1", name: "str" },
    { _id: "2", name: "dex" },
    { _id: "3", name: "con" },
    { _id: "4", name: "int" },
    { _id: "5", name: "wis" },
    { _id: "6", name: "cha" },
];

export const ALIGNMENTS: ISimpleSchema[] = [
    { _id: "1", name: "any" },
    { _id: "2", name: "lawful good" },
    { _id: "3", name: "lawful neutral" },
    { _id: "4", name: "lawful evil" },
    { _id: "5", name: "neutral good" },
    { _id: "6", name: "neutral" },
    { _id: "6", name: "neutral evil" },
    { _id: "6", name: "chaotic good" },
    { _id: "6", name: "chaotic neutral" },
    { _id: "6", name: "chaotic evil" }
]