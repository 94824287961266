import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../types/UserTypes";
import { RootState } from "../store";

export interface UserState {
    user?: User,
    authToken?: string
}

const initialState: UserState = {}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload;
        },
        setUser: (state, action: PayloadAction<User | undefined>) => {
            state.user = action.payload;
        }
    }
});

export const {
    setAuthToken,
    setUser
} = userSlice.actions;

export const selectAuthToken = (state: RootState) => state.user.authToken;
export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;