import { ISimpleSchema } from "../types/CreatureTypes";
import { IDistanceSchema, IDurationSchema, ISpell } from "../types/SpellTypes";

export const getSpellLevelText = (spell: ISpell | undefined) => {
    let text = "";
    if (spell) {
        if (spell.level === 0) {
            text = "Cantrip " +  spell.school?.name;
        } else {
            text = `Level ${spell.level} ${spell.school?.name}`
        }
    }
    return text;
};

export const getSpellTypeLine1 = (spell: ISpell | undefined) => {
    let typeLine = "";
    if (spell) {
        const classList = [...spell.classes, ...spell.subclasses];
        classList.forEach((c, i) => {
            typeLine += c.name;
            if (i !== classList.length - 1) {
                typeLine += ", ";
            }
        });
    }
    return typeLine;
};

export const getSpellTypeLine2 = (spell: ISpell) => {
    let typeLine = "";
    if (spell.ritual) {
        typeLine += "Rit";
    }
    if (spell.concentration) {
        if (typeLine) {
            typeLine += ", ";
        }
        typeLine += "Con"
    }
    if (spell.school) {
        if (typeLine) {
            typeLine += ", ";
        }
        typeLine += spell.school.name;
    }
    if (spell.components.length > 0) {
        if (typeLine) {
            typeLine += " ";
        }
        typeLine += "("
        spell.components.forEach((c, i) => {
            typeLine += c.name;
            if (i !== spell.components.length - 1) {
                typeLine += ", ";
            }
        })
        typeLine += ")";
    }
    return typeLine;
};

export const getDurationText = (castingTime: IDurationSchema | undefined) => {
    let text = "";
    if (castingTime && castingTime.time > 0) {
        text = `${castingTime.time} ${castingTime.timeUnit.name}`;
    } else if (castingTime) {
        text = castingTime.timeUnit.name;
    }
    return text;
}

export const getRangeText = (range: IDistanceSchema | undefined) => {
    let text = "";
    if (range && range.distance > 0) {
        text = `${range.distance} ${range.distanceUnit.name}`;
    } else if (range) {
        text = range.distanceUnit.name;
    }
    return text;
}

export const getComponentText = (list: ISimpleSchema[] | undefined) => {
    let text = "";
    if (list) {
        list.forEach((c, i) => {
            text += c.name;
            if (i !== list.length - 1) {
                text += ", ";
            }
        });
    }
    return text;
}