import { getXpByCr } from "../../constants/Cr.constants";
import Status from "../../constants/CreatureStatus";
import Teams from "../../constants/Teams.constants";
import { IInitItem } from "../../types/InitiativeTypes";

export interface XpItem {
    name: string;
    qty: number;
    xp: number;
}

const calculateXp = (initOrder: IInitItem[]): [number, number, XpItem[]] => {
    const items: XpItem[] = [];
    let total = 0;
    initOrder.filter(c => c.status === Status.DEAD && c.team === Teams.ENEMY)
        .forEach(c => {
            let isUsed = false;
            for (let i = 0; i < items.length; i++) {
                if (items[i].name === c.nameFiller) {
                    isUsed = true;
                    break;
                }
            }
            if (!isUsed) {
                let count = 0;
                initOrder.forEach(c2 => {
                    if (c2.nameFiller === c.nameFiller) count++;
                })
                items.push({
                    name: c.nameFiller,
                    qty: count,
                    xp: getXpByCr(c.cr)
                })
                total += count * getXpByCr(c.cr);
            }
        })
    const nPlayers = initOrder.filter(c => c.team === Teams.PLAYER).length;
    return [total, nPlayers, items];
}

export default calculateXp;