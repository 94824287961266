import { Box, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import DsButton from "../../input/DsButton/DsButton";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import DsInput from "../../input/DsInput/DsInput";
import { BiSave } from 'react-icons/bi';
import { ISimpleSchema } from "../../../types/CreatureTypes";
import SimpleSchemaListEditor from "../../input/listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { selectAuthToken } from "../../../redux/slices/userSlice";
import { createMagicItem } from "../../../api/postData";
import { setAddMagicItemActive } from "../../../redux/slices/modalSlice";
import { updateMagicItem } from "../../../api/updateData";
import { addNewItemType, addNewRarity, addNewSource, selectCSBItemTypes, selectCSBRarities, selectCSBSources } from "../../../redux/slices/statblockItemSlice";
import { createNewItemsNoValue } from "../../../helper/createNewItems";
import { selectCopyMagicItem, selectSelectedMagicItem, setCopyMagicItem, setSelectedMagicItem } from "../../../redux/slices/magicItemSlice";
import { IMagicItem } from "../../../types/MagicItemTypes";
import DsTextarea from "../../input/DsTextarea/DsTextarea";

const ContentGrid = styled(Grid)({});

const AddMagicItemModal = () => {

    const [name, setName] = useState<string>("");
    const [itemTypes, setItemTypes] = useState<ISimpleSchema[]>([]);
    const [rarity, setRarity] = useState<ISimpleSchema[]>([]);
    const [requiresAttunement, setRequiresAttunement] = useState<string>("false");
    const [description, setDescription] = useState<string>("");
    const [source, setSource] = useState<ISimpleSchema[]>([]);

    const dispatch = useAppDispatch();
    const authToken = useAppSelector(selectAuthToken);
    const selectedMagicItem = useAppSelector(selectSelectedMagicItem);
    const copyMagicItem = useAppSelector(selectCopyMagicItem);
    const sbRarities = useAppSelector(selectCSBRarities);
    const sbItemTypes = useAppSelector(selectCSBItemTypes);
    const sbSources = useAppSelector(selectCSBSources);

    useEffect(() => {
        if (selectedMagicItem) {
            setName(copyMagicItem ? "Copy of " + selectedMagicItem.name : selectedMagicItem.name);
            setItemTypes(selectedMagicItem.item_type ? selectedMagicItem.item_type : []);
            setRarity(selectedMagicItem.rarity ? [{ ...selectedMagicItem.rarity }] : []);
            setRequiresAttunement(selectedMagicItem.requires_attunement + "");
            setDescription(selectedMagicItem.description);
            setSource(selectedMagicItem.source && typeof selectedMagicItem.source === "object" ? [{ ...selectedMagicItem.source }] : []);
        }
    }, [selectedMagicItem]);

    const handleAdd = async () => {

        const newItemTypes = await createNewItemsNoValue(itemTypes, "itemType", addNewItemType);
        const newRarities = await createNewItemsNoValue(rarity, "rarity", addNewRarity);
        const newSources = await createNewItemsNoValue(source, "source", addNewSource);

        const newMagicItem: IMagicItem = selectedMagicItem && !copyMagicItem ? {
            ...selectedMagicItem,
            name,
            item_type: newItemTypes ? newItemTypes : [],
            rarity: newRarities ? newRarities[0] : undefined,
            requires_attunement: requiresAttunement === "true",
            description,
            source: newSources && newSources?.length > 0 ? newSources[0] : selectedMagicItem.source
        } : {
            name,
            item_type: newItemTypes,
            rarity: newRarities ? newRarities[0] : undefined,
            requires_attunement: requiresAttunement === "true",
            description,
            source: newSources && newSources?.length > 0 ? newSources[0] : "62081d41cd68980016f931af"
        }

        let res;

        if (selectedMagicItem && !copyMagicItem) {
            res = await updateMagicItem(newMagicItem);
        } else {
            res = await createMagicItem(newMagicItem, authToken);
        }

        if (res) {
            dispatch(setSelectedMagicItem(undefined));
            dispatch(setCopyMagicItem(false));
            dispatch(setAddMagicItemActive(false));
        }
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>
                {/* Row */}
                <DsInput xs={12} label={"Name"} value={name} setValue={setName} name="name" />

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Item Type"
                        itemName="Item Type"
                        list={itemTypes}
                        setList={setItemTypes}
                        dropdownList={sbItemTypes}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Rarity"
                        itemName="Rarity"
                        list={rarity}
                        setList={setRarity}
                        dropdownList={sbRarities}
                        maxListLength={1}
                    />
                </Grid>

                {/* Row */}
                <DsDropdown xs={12}
                    name="requiresAttunement"
                    label="Requires Attunement"
                    value={requiresAttunement}
                    setValue={setRequiresAttunement}
                    data={[{ value: "true" }, { value: "false" }]}
                    attribute="value"
                />

                {/* Row */}
                <DsTextarea xs={12}
                    name="description"
                    label="Description"
                    value={description}
                    setValue={setDescription}
                />

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Source"
                        itemName="Source"
                        list={source}
                        setList={setSource}
                        dropdownList={sbSources}
                        maxListLength={1}
                    />
                </Grid>

                {/* Row */}
                <DsButton xs={12}
                    name="add"
                    icon={<BiSave fontSize={"20px"} />}
                    label="Save Magic Item"
                    onClick={handleAdd}
                />
            </ContentGrid>
        </Box>
    )
}

export default AddMagicItemModal;
