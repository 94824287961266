import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ICondition, ISkill, IStatblockItems } from '../../types/StatblockTypes'
import { ICreatureType, IDamageType, ILanguage, IMovement, ISense, ISimpleSchema, ISize, ISource } from '../../types/CreatureTypes'
import { RootState } from '../store'
import { sortByName } from '../../helper/searching/sorts'

interface StatblockItemsState {
    conditions: ICondition[]
    creature_types: ICreatureType[]
    damage_types: IDamageType[]
    languages: ILanguage[]
    movements: IMovement[]
    senses: ISense[]
    sizes: ISize[]
    skills: ISkill[]
    sources: ISimpleSchema[],
    itemTypes: ISimpleSchema[]
    rarities: ISimpleSchema[]
    attack_types: ISimpleSchema[],
    time_units: ISimpleSchema[],
    classes: ISimpleSchema[],
    spell_components: ISimpleSchema[],
    distance_units: ISimpleSchema[],
    subclasses: ISimpleSchema[],
    magic_schools: ISimpleSchema[],

    newConditions: ICondition[]
    newCreature_types: ICreatureType[]
    newDamage_types: IDamageType[]
    newLanguages: ILanguage[]
    newMovements: IMovement[]
    newSenses: ISense[]
    newSizes: ISize[]
    newSkills: ISkill[]
    newSources: ISource[]
}

const initialState: StatblockItemsState = {
    conditions: [],
    creature_types: [],
    damage_types: [],
    languages: [],
    movements: [],
    senses: [],
    sizes: [],
    skills: [],
    sources: [],
    itemTypes: [],
    rarities: [],
    attack_types: [],
    time_units: [],
    classes: [],
    spell_components: [],
    distance_units: [],
    subclasses: [],
    magic_schools: [],

    newConditions: [],
    newCreature_types: [],
    newDamage_types: [],
    newLanguages: [],
    newMovements: [],
    newSenses: [],
    newSizes: [],
    newSkills: [],
    newSources: []
}

export const statblockItemSlice = createSlice({
    name: 'statblockItems',
    initialState,
    reducers: {
        setStatblockItems: (state, action: PayloadAction<IStatblockItems>) => {
            state.sources = action.payload.source ? sortByName([...action.payload.source]) : []
            state.creature_types = action.payload.creature_types ? sortByName([...action.payload.creature_types]) : []
            state.damage_types = action.payload.damage_types ? sortByName([...action.payload.damage_types]) : []
            state.conditions = action.payload.conditions ? sortByName([...action.payload.conditions]) : []
            state.senses = action.payload.senses ? sortByName([...action.payload.senses]) : []
            state.sizes = action.payload.sizes ? sortByName([...action.payload.sizes]) : []
            state.movements = action.payload.movements ? sortByName([...action.payload.movements]) : []
            state.skills = action.payload.skills ? sortByName([...action.payload.skills]) : []
            state.languages = action.payload.languages ? sortByName([...action.payload.languages]) : []
            state.itemTypes = action.payload.item_types ? sortByName([...action.payload.item_types]) : [];
            state.rarities = action.payload.rarities ? sortByName([...action.payload.rarities]) : []
            state.attack_types = action.payload.attack_types ? sortByName([...action.payload.attack_types]) : []
            state.time_units = action.payload.time_units ? sortByName([...action.payload.time_units]) : []
            state.classes = action.payload.classes ? sortByName([...action.payload.classes]) : []
            state.spell_components = action.payload.spell_components ? sortByName([...action.payload.spell_components]) : []
            state.distance_units = action.payload.distance_units ? sortByName([...action.payload.distance_units]) : []
            state.subclasses = action.payload.subclasses ? sortByName([...action.payload.subclasses]) : []
            state.magic_schools = action.payload.magic_schools ? sortByName([...action.payload.magic_schools]) : []
        },
        setConditions: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.conditions = action.payload;
        },
        setCreatureTypes: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.creature_types = action.payload;
        },
        setDamageTypes: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.damage_types = action.payload;
        },
        setLanguages: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.languages = action.payload;
        },
        setMovements: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.movements = action.payload;
        },
        setSenses: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.senses = action.payload;
        },
        setSizes: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.sizes = action.payload;
        },
        setSkills: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.skills = action.payload;
        },
        setSources: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.sources = action.payload;
        },
        setItemTypes: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.itemTypes = action.payload;
        },
        setRarities: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.rarities = action.payload;
        },
        setAttackTypes: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.attack_types = action.payload;
        },
        setTimeUnits: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.time_units = action.payload;
        },
        setClasses: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.classes = action.payload;
        },
        setSpellComponents: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.spell_components = action.payload;
        },
        setDistanceUnits: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.distance_units = action.payload;
        },
        setSubclasses: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.subclasses = action.payload;
        },
        setMagicSchools: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.magic_schools = action.payload;
        },

        addNewCondition: (state, action: PayloadAction<ICondition>) => {
            const newItem = action.payload
            state.conditions = sortByName([...state.conditions, newItem]);
        },
        addNewCreatureType: (state, action: PayloadAction<ICreatureType>) => {
            const newItem = action.payload
            state.creature_types = sortByName([...state.creature_types, newItem]);
        },
        addNewDamageType: (state, action: PayloadAction<IDamageType>) => {
            const newItem = action.payload
            state.damage_types = sortByName([...state.damage_types, newItem]);
        },
        addNewLanguage: (state, action: PayloadAction<ILanguage>) => {
            const newItem = action.payload
            state.languages = sortByName([...state.languages, newItem]);
        },
        addNewMovement: (state, action: PayloadAction<IMovement>) => {
            const newItem = action.payload
            state.movements = sortByName([...state.movements, newItem]);
        },
        addNewSense: (state, action: PayloadAction<ISense>) => {
            const newItem = action.payload
            state.senses = sortByName([...state.senses, newItem]);
        },
        addNewSize: (state, action: PayloadAction<ISize>) => {
            const newItem = action.payload
            state.sizes = sortByName([...state.sizes, newItem]);
        },
        addNewSkill: (state, action: PayloadAction<ISkill>) => {
            const newItem = action.payload
            state.skills = sortByName([...state.skills, newItem]);
        },
        addNewSource: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.sources = sortByName([...state.sources, newItem]);
        },
        addNewItemType: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.itemTypes = sortByName([...state.itemTypes, newItem]);
        },
        addNewRarity: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.rarities = sortByName([...state.rarities, newItem]);
        },
        addNewAttackType: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.attack_types = sortByName([...state.attack_types, newItem]);
        },
        addNewTimeUnit: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.time_units = sortByName([...state.time_units, newItem]);
        },
        addNewClass: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.classes = sortByName([...state.classes, newItem]);
        },
        addNewSpellComponent: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.spell_components = sortByName([...state.spell_components, newItem]);
        },
        addNewDistanceUnit: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.distance_units = sortByName([...state.distance_units, newItem]);
        },
        addNewSubclass: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.subclasses = sortByName([...state.subclasses, newItem]);
        },
        addNewMagicSchool: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            state.magic_schools = sortByName([...state.magic_schools, newItem]);
        },

        editCondition: (state, action: PayloadAction<ICondition>) => {
            const newItem = action.payload
            let i = 0
            state.conditions.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.conditions]
            newArr[i] = newItem
            state.conditions = newArr
        },
        editCreatureType: (state, action: PayloadAction<ICreatureType>) => {
            const newItem = action.payload
            let i = 0
            state.creature_types.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.creature_types]
            newArr[i] = newItem
            state.creature_types = newArr
        },
        editDamageType: (state, action: PayloadAction<IDamageType>) => {
            const newItem = action.payload
            let i = 0
            state.damage_types.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.damage_types]
            newArr[i] = newItem
            state.damage_types = newArr
        },
        editLanguage: (state, action: PayloadAction<ILanguage>) => {
            const newItem = action.payload
            let i = 0
            state.languages.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.languages]
            newArr[i] = newItem
            state.languages = newArr
        },
        editMovement: (state, action: PayloadAction<IMovement>) => {
            const newItem = action.payload
            let i = 0
            state.movements.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.movements]
            newArr[i] = newItem
            state.movements = newArr
        },
        editSense: (state, action: PayloadAction<ISense>) => {
            const newItem = action.payload
            let i = 0
            state.senses.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.senses]
            newArr[i] = newItem
            state.senses = newArr
        },
        editSize: (state, action: PayloadAction<ISize>) => {
            const newItem = action.payload
            let i = 0
            state.sizes.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.sizes]
            newArr[i] = newItem
            state.sizes = newArr
        },
        editSkill: (state, action: PayloadAction<ISkill>) => {
            const newItem = action.payload
            let i = 0
            state.skills.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.skills]
            newArr[i] = newItem
            state.skills = newArr
        },
        editSource: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.sources.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.sources]
            newArr[i] = newItem
            state.sources = newArr
        },
        editItemTypes: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.itemTypes.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.itemTypes]
            newArr[i] = newItem
            state.itemTypes = newArr
        },
        editRarities: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.rarities.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.rarities]
            newArr[i] = newItem
            state.rarities = newArr
        },
        editAttackType: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.attack_types.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.attack_types]
            newArr[i] = newItem
            state.attack_types = newArr
        },
        editTimeUnit: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.time_units.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.time_units]
            newArr[i] = newItem
            state.time_units = newArr
        },
        editClass: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.classes.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.classes]
            newArr[i] = newItem
            state.classes = newArr
        },
        editSpellComponent: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.spell_components.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.spell_components]
            newArr[i] = newItem
            state.spell_components = newArr
        },
        editDistanceUnit: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.distance_units.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.distance_units]
            newArr[i] = newItem
            state.distance_units = newArr
        },
        editSubclass: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.subclasses.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.subclasses]
            newArr[i] = newItem
            state.subclasses = newArr
        },
        editMagicSchool: (state, action: PayloadAction<ISimpleSchema>) => {
            const newItem = action.payload
            let i = 0
            state.magic_schools.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.magic_schools]
            newArr[i] = newItem
            state.magic_schools = newArr
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setStatblockItems,
    setConditions,
    setCreatureTypes,
    setDamageTypes,
    setLanguages,
    setMovements,
    setSenses,
    setSizes,
    setSkills,
    setSources,
    setItemTypes,
    setRarities,
    setAttackTypes,
    setTimeUnits,
    setClasses,
    setSpellComponents,
    setDistanceUnits,
    setSubclasses,
    setMagicSchools,

    addNewCondition,
    addNewCreatureType,
    addNewDamageType,
    addNewLanguage,
    addNewMovement,
    addNewSense,
    addNewSize,
    addNewSkill,
    addNewSource,
    addNewItemType,
    addNewRarity,
    addNewAttackType,
    addNewTimeUnit,
    addNewClass,
    addNewSpellComponent,
    addNewDistanceUnit,
    addNewSubclass,
    addNewMagicSchool,

    editCondition,
    editCreatureType,
    editDamageType,
    editLanguage,
    editMovement,
    editSense,
    editSize,
    editSkill,
    editSource,
    editItemTypes,
    editRarities,
    editAttackType,
    editTimeUnit,
    editClass,
    editSpellComponent,
    editDistanceUnit,
    editSubclass,
    editMagicSchool
} = statblockItemSlice.actions

export const selectCreatureStatBlockItems = (state: RootState) => state.statblockItems
export const selectCSBConditions = (state: RootState) => state.statblockItems.conditions
export const selectCSBCreatureTypes = (state: RootState) => state.statblockItems.creature_types
export const selectCSBDamageTypes = (state: RootState) => state.statblockItems.damage_types
export const selectCSBLanguages = (state: RootState) => state.statblockItems.languages
export const selectCSBMovements = (state: RootState) => state.statblockItems.movements
export const selectCSBSenses = (state: RootState) => state.statblockItems.senses
export const selectCSBSizes = (state: RootState) => state.statblockItems.sizes
export const selectCSBSkills = (state: RootState) => state.statblockItems.skills
export const selectCSBSources = (state: RootState) => state.statblockItems.sources
export const selectCSBItemTypes = (state: RootState) => state.statblockItems.itemTypes
export const selectCSBRarities = (state: RootState) => state.statblockItems.rarities
export const selectCSBAttackTypes = (state: RootState) => state.statblockItems.attack_types
export const selectCSBTimeUnits = (state: RootState) => state.statblockItems.time_units
export const selectCSBClasses = (state: RootState) => state.statblockItems.classes
export const selectCSBSpellComponents = (state: RootState) => state.statblockItems.spell_components
export const selectCSBDistanceUnits = (state: RootState) => state.statblockItems.distance_units
export const selectCSBSubclasses = (state: RootState) => state.statblockItems.subclasses
export const selectCSBMagicSchools = (state: RootState) => state.statblockItems.magic_schools

export default statblockItemSlice.reducer