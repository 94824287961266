import { Box, Typography, styled } from "@mui/material";
import { useState } from "react";
import myTheme from "../../../myTheme";

const DropdownHead = styled(Typography)({
    width: "100%",
    padding: "5px",
    color: myTheme.light,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: myTheme.primary,
    borderRadius: "5px",
    boxSizing: "border-box"
});

const DropdownHeadActive = styled(Box)({
    width: "100%",
    padding: "5px",
    color: myTheme.light,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: myTheme.primary,
    borderRadius: "5px",
    boxSizing: "border-box",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px"
});

const DropdownBody = styled(Box)({
    width: "100%",
    borderColor: myTheme.primary,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    border: "2px solid " + myTheme.primary,
    borderTopWidth: "0",
    color: myTheme.light,
    textAlign: "left",
    padding: "5px 10px",
    boxSizing: "border-box",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
});

export interface AccordionItemProps {
    label: string;
    text?: string;
    content?: any;
    defaultExpand?: boolean;
}

const AccordionItem = ({ label, text, content, defaultExpand }: AccordionItemProps) => {
    const [active, setActive] = useState<boolean>(defaultExpand ? true : false);

    const handleClick = () => {
        setActive(prev => !prev);
    };

    return (
        <Box>
            {active ? (
                <DropdownHeadActive onClick={handleClick}>{label}</DropdownHeadActive>
            ) : (
                <DropdownHead onClick={handleClick}>{label}</DropdownHead>
            )}
            {active && text && (
                <DropdownBody>{text}</DropdownBody>
            )}
            {active && content && (
                <DropdownBody>{content}</DropdownBody>
            )}
        </Box>
    );
};

export default AccordionItem;