import { Box } from "@mui/material";
import { Buffer } from 'buffer';

const D20Adv = ({ size = 10, stroke = 'black', fill1 = 'none', fill2 = 'none', strokeWidth = 10, onClick }: any) => {

    const xml = `
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530.82 609.26">
                <defs>
                    <style>
                        .cls-1, .cls-4 {
                            fill: ${fill1};
                            stroke-width: ${strokeWidth};
                        }
    
                        .cls-1, .cls-2, .cls-3, .cls-4 {
                            stroke: ${stroke};
                            stroke-miterlimit: 10;
                            stroke-width: ${strokeWidth};
                        }
    
                        .cls-2 {
                            fill: ${fill1};
                        }
    
                        .cls-4 {
                            fill: ${fill2};
                        }
                    </style>
                </defs>
                <g id="Layer_3" data-name="Layer 3">
                    <g>
                        <polygon class="cls-1" points="262.34 603.34 526.66 455.15 520.66 148.77 264.34 4.59 6.02 150.78 4.01 453.15 262.34 603.34"/>
                        <polygon class="cls-2" points="262.34 603.34 446.57 413.1 520.66 148.77 264.34 80.69 6.02 150.78 80.11 409.1 262.34 603.34"/>
                        <polygon class="cls-2" points="80.11 409.1 264.34 80.69 446.57 413.1 80.11 409.1"/>
                        <line class="cls-2" x1="4.02" y1="453.15" x2="80.11" y2="409.1"/>
                        <line class="cls-2" x1="526.67" y1="455.15" x2="446.57" y2="413.1"/>
                        <path class="cls-3" d="M305.5,82.22v0Z" transform="translate(-41.16 -1.53)"/>
                        <path class="cls-3" d="M305.5,82.22" transform="translate(-41.16 -1.53)"/>
                        <path class="cls-3" d="M305.5,6.13" transform="translate(-41.16 -1.53)"/>
                        <line class="cls-2" x1="264.34" y1="80.69" x2="264.34" y2="4.59"/>
                        <polygon class="cls-4" points="367.07 76.3 310.94 76.3 310.94 127.79 367.07 127.79 367.07 184.07 423.35 184.07 423.35 127.79 479.63 127.79 479.63 76.3 423.35 76.3 423.35 19.87 367.07 19.87 367.07 76.3"/>
                    </g>
                </g>
            </svg>
        `

    const buff = Buffer.from(xml);
    const base64data = buff.toString("base64");

    return (
        <Box
            sx={{
                width: size ? `${size}px` : "100%",
                height: size ? `${size}px` : "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}
            data-testid="d20AdvIcon__container"
            onClick={onClick}
        >
            <img src={`data:image/svg+xml;base64,${base64data}`} alt=""
                style={{
                    height: "100%"
                }}
            />
        </Box>
    )
}

export default D20Adv;