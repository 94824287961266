import { Box, Grid, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { editInitItem, selectLivingPlayers } from "../../../redux/slices/initSlice";
import { setRerollInitActive } from "../../../redux/slices/modalSlice";
import RerollInitEntry from "../../inititivePageComponents/RerollInitEntry/RerollInitEntry";
import DsButton from "../../input/DsButton/DsButton";
import { BiSave } from "react-icons/bi";

// const Container = styled(Box)({
//     display: "flex",
//     flexDirection: "column",

// })

const RerollInitModal = () => {

    const [inits, setInits] = useState<string[]>([]);

    const players = useAppSelector(selectLivingPlayers);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const newInits: string[] = []
        players.forEach(p => {
            newInits.push(" ")
        })
        setInits(newInits);
    }, [])

    const saveInits = () => {
        players.forEach((p, i) => {
            const init = parseInt(inits[i])
            if(init && !isNaN(init)) {
                const newPlayer = {
                    ...p,
                    initiative: init
                }
                dispatch(editInitItem(newPlayer))
            }
        })
        dispatch(setRerollInitActive(false))
    }

    return (
        <Grid container>
            {players.map((item, index) => (
                <RerollInitEntry 
                    key={item._id}
                    item={item}
                    index={index}
                    setInits={setInits}
                />
            ))}
            <DsButton xs={12} icon={<BiSave fontSize={"20px"} />} label="Save" onClick={saveInits} />
        </Grid>
    )
}

export default RerollInitModal;