import { Box, Grid, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectCSBItemTypes, selectCSBRarities } from "../../../redux/slices/statblockItemSlice";
import { resetItemSortAndFilter, selectItemFilterbyAttunement, selectItemRarityFilterList, selectItemRarityFilterText, selectItemTypeFilterList, selectItemTypeFilterText, selectSortItemsBy, setItemFilterByAttunement, setItemRarityFilterList, setItemRarityFilterText, setItemTypeFilterList, setItemTypeFilterText, setSortItemsBy } from "../../../redux/slices/magicItemSlice";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import { MagicItemSortByOptions } from "../../../types/MagicItemTypes";
import DsFilter from "../../input/DsFilter/DsFilter";
import DsButton from "../../input/DsButton/DsButton";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const ContentGrid = styled(Grid)({});

const FilterMagicItemModal = () => {
    const sbRarities = useAppSelector(selectCSBRarities);
    const sbItemtypes = useAppSelector(selectCSBItemTypes);

    const sortMagicItemsBy = useAppSelector(selectSortItemsBy);
    const rarityFilterList = useAppSelector(selectItemRarityFilterList);
    const rarityFilterText = useAppSelector(selectItemRarityFilterText);
    const typeFilterList = useAppSelector(selectItemTypeFilterList);
    const typeFilterText = useAppSelector(selectItemTypeFilterText);
    const filterByAttunement = useAppSelector(selectItemFilterbyAttunement);

    const dispatch = useAppDispatch();

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>

                <DsDropdown xs={12}
                    name="sortBy"
                    label="Sort By"
                    value={sortMagicItemsBy}
                    setValue={(e: any) => dispatch(setSortItemsBy(e))}
                    data={[{ value: MagicItemSortByOptions.name }, { value: MagicItemSortByOptions.rarity }]}
                    attribute="value"
                />

                <DsDropdown xs={12}
                    name="attunement"
                    label="Attunement"
                    value={filterByAttunement}
                    setValue={(e: any) => dispatch(setItemFilterByAttunement(e))}
                    data={[{ value: "Either" }, { value: "Yes" }, { value: "No" }]}
                    attribute="value"
                />

                <Grid item xs={12}>
                    <DsFilter
                        label="Rarity"
                        name="rarity"
                        list={rarityFilterList}
                        setList={(e: any) => {
                            dispatch(setItemRarityFilterList(e(rarityFilterList)))
                        }}
                        textValue={rarityFilterText}
                        setTextValue={(e: any) => dispatch(setItemRarityFilterText(e))}
                        dropdownList={sbRarities}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsFilter
                        label="Item Type"
                        name="itemType"
                        list={typeFilterList}
                        setList={(e: any) => {
                            dispatch(setItemTypeFilterList(e(typeFilterList)))
                        }}
                        textValue={typeFilterText}
                        setTextValue={(e: any) => dispatch(setItemTypeFilterText(e))}
                        dropdownList={sbItemtypes}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsButton
                        label="Reset Filters"
                        icon={<ReplayOutlinedIcon sx={{ fontSize: "16px" }} />}
                        onClick={() => dispatch(resetItemSortAndFilter())}
                    />
                </Grid>
            </ContentGrid>
        </Box>
    )
}

export default FilterMagicItemModal;
