import React from "react";
import { Autocomplete, AutocompleteProps, Box, GridProps, MenuItem, SelectChangeEvent, TextField, styled } from "@mui/material";
import { useState } from "react";
import myTheme from "../../../myTheme";
import DsInput, { InputLabel, InputWrapper } from "../DsInput/DsInput";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectCreatures } from "../../../redux/slices/creatureSlice";
import { ICreature } from "../../../types/CreatureTypes";

const AutocompleteContainer = styled(Box)({
    display: "flex",
    boxSizing: "border-box",
    "& .MuiAutocomplete-root": {
        display: "flex",
        paddingLeft: "5px"
    }
});

const StyledAutocomplete = styled(Autocomplete<ICreature>)({
    width: "100%",
    background: myTheme.medDark,
    color: myTheme.medGray,
    borderRadius: "5px",
    height: "30px",
    fontSize: "14px",
    "& .MuiButtonBase-root": {
        color: myTheme.medGray
    }
    // padding: "0 !important",
    
    // paddingLeft: "5px",
    // paddingRight: "5px",
    // border: "none",
    // ":focus": {
    //     backgroundColor: "purple",
    //     outline: "none",
    //     textDecoration: "none",
    // },
    // "& .MuiSelect-select": {
    //     padding: 0
    // },
    // "& svg": {
    //     color: myTheme.offWhite
    // }
})

const StyledInput = styled(TextField)({
    "& .MuiInputBase-root": {
        padding: "0 !important",
        height: "100%",
        width: "100%",
        border: "none",
        outline: "none"
    },
    "& input": {
        padding: "0 !important",
        color: myTheme.medGray,
        fontSize: "14px",
        paddingLeft: "5px"
    },
    "& fieldset": {
        border: "none",
        outlinr: "none"
    }
});

const StyledOption = styled(MenuItem)({
    background: myTheme.medDark,
    color: myTheme.medGray,
    height: "30px",
    fontSize: "14px",
    paddingLeft: "5px",
    paddingRight: "5px",
    "& :checked": {
        backgroundColor: "red"
    }
})

export interface DsAutocompleteProps extends GridProps {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    label: string;
}

const StatblockAutocomplete = ({ value, setValue, label, ...props }: any) => {

    const creatures: ICreature[] = useAppSelector(selectCreatures);

    return (
        <InputWrapper data-testid={`statblockAutocomplete__container`} item {...props}>
            <InputLabel data-testid={`statblockAutocomplete__label`}>{label}</InputLabel>
            <AutocompleteContainer>
                <StyledAutocomplete
                    options={creatures}
                    renderInput={(params) => <StyledInput {...params} />}
                    ListboxProps={{
                        sx: {
                            backgroundColor: myTheme.bg,
                            color: myTheme.medGray,
                            border: "1px solid black"
                        }
                    }}
                    value={value}
                    onChange={(_event: any, newValue: ICreature | null) => {
                        setValue(newValue);
                      }}
                    getOptionLabel={(option) => option.name}
                />
            </AutocompleteContainer>
        </InputWrapper>
    )
}

export default StatblockAutocomplete;