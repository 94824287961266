import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit'
import initSlice from './slices/initSlice'
import modalSlice from './slices/modalSlice'
import userSlice from './slices/userSlice';
import creatureSlice from './slices/creatureSlice';
import statblockItemSlice from './slices/statblockItemSlice';
import magicItemSlice from './slices/magicItemSlice';
import spellSlice from './slices/spellSlice';
import dungeonsuiteSlice from './slices/dungeonsuiteSlice';
import friendSlice from './slices/friendSlice';

const rootReducer = combineReducers({
    init: initSlice,
    modal: modalSlice,
    user: userSlice,
    creatures: creatureSlice,
    statblockItems: statblockItemSlice,
    magicItems: magicItemSlice,
    spells: spellSlice,
    dungeonsuite: dungeonsuiteSlice,
    friends: friendSlice
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    })
}

export default setupStore();

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']