import { Grid, useMediaQuery, useTheme } from "@mui/material";
import myTheme from "../../myTheme";
import { ContentContainer, OuterContainer } from "../Pages.styled";
import { HeaderContainer, Heading, SearchBar } from "../CreaturePage/CreaturePage";
import { useEffect, useRef, useState } from "react";
import { List } from "react-virtualized";
import MagicItemLi from "../../components/magicItemPageComponents/MagicItemLi/MagicItemLi";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectItemFilterbyAttunement, selectItemRarityFilterList, selectItemRarityFilterText, selectItemSearch, selectItemTypeFilterList, selectItemTypeFilterText, selectMagicItem, selectSortItemsBy, setCopyMagicItem, setItemSearch, setSelectedMagicItem } from "../../redux/slices/magicItemSlice";
import GenericModal from "../../components/modals/GenericModal/GenericModal";
import { selectAddMagicItemActive, selectFilterMagicItemActive, selectRandomMagicItemActive, selectViewMagicItemActive, setAddMagicItemActive, setFilterMagicItemActive, setRandomMagicItemActive, setViewMagicItemActive } from "../../redux/slices/modalSlice";
import ViewMagicItemModal from "../../components/modals/ViewMagicItemModal/ViewMagicItemModal";
import AddMagicItemModal from "../../components/modals/AddMagicItemModal/AddMagicItemModal";
import { IMagicItem, MagicItemSortByOptions } from "../../types/MagicItemTypes";
import DsLoader from "../../components/dataDisplay/DsLoader/Loader";
import AddFab from "../../components/input/fabs/AddFab/AddFab";
import FilterFab from "../../components/input/fabs/FilterFab/FilterFab";
import FilterMagicItemModal from "../../components/modals/FilterMagicItemModal/FilterMagicItemModal";
import { compareSimpleSchemaLists, simpleSchemaListIncludes, simpleSchemaListIncludesName } from "../../helper/searching/searches";
import { sortByName, sortByRarity } from "../../helper/searching/sorts";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import LoginButton from "../../components/input/buttons/LoginButton";
import RandomFab from "../../components/input/fabs/RandomFab/RandomFab";
import RandomMagicItemModal from "../../components/modals/RandomMagicItemModal/RandomMagicItemModal";
import { selectCreatureSearch } from "../../redux/slices/creatureSlice";
import { selectSpellSearch, setSpellSearch } from "../../redux/slices/spellSlice";

const ItemPage = () => {
    const [listHeight, setListHeight] = useState<number>(600);
    const [filteredList, setFilteredList] = useState<IMagicItem[]>([]);

    const [dsToken] = usePersistedState<string>("dsToken", "");

    const magicItems = useAppSelector(selectMagicItem);

    const sortMagicItemsBy = useAppSelector(selectSortItemsBy);
    const rarityFilterList = useAppSelector(selectItemRarityFilterList);
    const rarityFilterText = useAppSelector(selectItemRarityFilterText);
    const typeFilterList = useAppSelector(selectItemTypeFilterList);
    const typeFilterText = useAppSelector(selectItemTypeFilterText);
    const filterByAttunement = useAppSelector(selectItemFilterbyAttunement);
    const search = useAppSelector(selectItemSearch);

    const dispatch = useAppDispatch()

    const listContainerRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const refHeight = listContainerRef.current?.getBoundingClientRect().height;
        setListHeight(refHeight ? refHeight : 600);
    }, []);

    useEffect(() => {
        if (magicItems.length > 0) {
            filter();
        }
    }, [magicItems]);

    useEffect(() => {
        filter();
    }, [
        search,
        sortMagicItemsBy,
        rarityFilterList,
        rarityFilterText,
        typeFilterList,
        typeFilterText,
        filterByAttunement
    ]);

    const filter = () => {
        const filtered = magicItems.filter(li => (
            (search && li.name.toUpperCase().includes(search.toUpperCase())
                || !search)
            && (rarityFilterText && li.rarity?.name.toUpperCase().includes(rarityFilterText.toUpperCase())
                || !rarityFilterText)
            && (rarityFilterList.length > 0 && simpleSchemaListIncludes(rarityFilterList, li.rarity)
                || rarityFilterList.length === 0)
            && (typeFilterText && simpleSchemaListIncludesName(li.item_type, typeFilterText)
                || !typeFilterText)
            && (typeFilterList.length > 0 && compareSimpleSchemaLists(typeFilterList, li.item_type)
                || typeFilterList.length === 0)
            && (filterByAttunement === "No" && !li.requires_attunement
                || filterByAttunement === "Yes" && li.requires_attunement
                || filterByAttunement === "Either")
        ));
        if (sortMagicItemsBy === MagicItemSortByOptions.name) {
            setFilteredList(sortByName(filtered));
        } else if (sortMagicItemsBy === MagicItemSortByOptions.rarity) {
            setFilteredList(sortByRarity(filtered));
        }
    };

    return (
        <OuterContainer data-testid="creaturePage__container">
            <GenericModal
                title="Create Magic Item"
                visibilitySelector={selectAddMagicItemActive}
                visibilityToggle={setAddMagicItemActive}
                Component={<AddMagicItemModal />}
                confirmOnClose
            />

            <GenericModal
                visibilitySelector={selectViewMagicItemActive}
                visibilityToggle={setViewMagicItemActive}
                Component={<ViewMagicItemModal />}
                onClose={() => {
                    setSelectedMagicItem(undefined);
                    setCopyMagicItem(false);
                }}
            />

            <GenericModal
                title="Filter Magic Items"
                visibilitySelector={selectFilterMagicItemActive}
                visibilityToggle={setFilterMagicItemActive}
                Component={<FilterMagicItemModal />}
            />

            <GenericModal
                title="Get a Random Magic Item"
                visibilitySelector={selectRandomMagicItemActive}
                visibilityToggle={setRandomMagicItemActive}
                Component={<RandomMagicItemModal />}
            />

            <HeaderContainer sx={{ backgroundColor: myTheme.itemYellow }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Heading>Magic Items</Heading>
                    </Grid>
                    <SearchBar xs={12}
                        name="search"
                        value={search}
                        setValue={(e: string) => dispatch(setItemSearch(e))}
                        placeholder="Search Magic Items"
                        useClearButton
                    />
                </Grid>
            </HeaderContainer>
            <ContentContainer ref={listContainerRef}>
                {magicItems.length > 0 && (
                    <List
                        width={window.screen.width}
                        height={listHeight}
                        rowHeight={isMobile ? 60 : 80}
                        rowRenderer={({ index, key, style }) => (
                            <MagicItemLi
                                magicItem={filteredList[index]}
                                key={key}
                                style={style}
                            />
                        )}
                        rowCount={filteredList.length}
                        overscanRowCount={5}
                        containerStyle={{
                            borderBottom: "3px solid black"
                        }}
                    />
                )}

                {magicItems.length === 0 && !dsToken && (
                    <LoginButton />
                )}

                {magicItems.length === 0 && dsToken && (
                    <DsLoader />
                )}
            </ContentContainer>
            <RandomFab visibilityToggle={setRandomMagicItemActive} />
            <AddFab
                visibilityToggle={setAddMagicItemActive}
                setSelected={setSelectedMagicItem}
            />
            <FilterFab visibilityToggle={setFilterMagicItemActive} />
        </OuterContainer>
    )
}

export default ItemPage;