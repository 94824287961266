import { v4 } from "uuid";
import { IAction, ISave, ISaveLi } from "../../types/CreatureTypes";

export const getSavesList = (list: ISaveLi[]) => {
    const newList: ISave[] = [];
    list.forEach(li => {
        newList.push({ 
            abilityScore: li.abilityScore.name,
            value: li.value
        })
    });
    return newList;
}

export const getSavesLiList = (list: ISave[]) => {
    const newList: ISaveLi[] = [];
    list.forEach(li => {
        newList.push({
            abilityScore: {
                _id: v4(),
                name: li.abilityScore
            },
            value: li.value
        });
    });
    return newList;
};

export const getAbilityMod = (score: number | undefined) => {
    if (!score) return "(+0)";
    const mod = Math.floor((score - 10) / 2);
    return mod >= 0 ? '(+' + mod + ')' : '(' + mod + ')';
};

export const getAbilityModFromString = (score: string | undefined) => {
    if (!score) return "(+0)";
    const num = parseInt(score);
    if (isNaN(num)) return "(+0)";
    return getAbilityMod(num);
};

export const getActionHeaderText = (action: IAction) => {
    let t = action.name
    if (action.usage) {
        const u = action.usage
        if (u.times) t += ` (${u.times} ${u.usageType})`
        else if (u.usageType === 'recharge on roll') t += ` (recharge ${u.min_value})`
    }
    return t
};