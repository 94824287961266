import { validatePosInt } from "../helper/validation/inputValidation"

const CR: {[key: string]: number} = {
    ZERO: 0,  
    EIGHTH: 0.125,  
    QUARTER: 0.25,
    HALF: 0.5
}

type CrStringType = {
    [key: string]: string
}

export const CR_STRING: CrStringType = {
    ZERO: '0',
    EIGHTH: '1/8',
    QUARTER: '1/4',
    HALF: '1/2'
}

export const parseNumericCR = (value: number | undefined) => {
    if (value === undefined) return "";
    if (value < 1) {
        if (value === CR.ZERO) return "0";
        if (value === CR.EIGHTH) return CR_STRING.EIGHTH;
        if (value === CR.QUARTER) return CR_STRING.QUARTER;
        if (value === CR.HALF) return CR_STRING.HALF;
    } else {
        return value + "";
    }
    return "";
}

export const parseCr = (value: string) => {
    let found = false;
    let result = 0;
    const keys = Object.keys(CR_STRING);
    keys.forEach((k: string, i) => {
        // console.log(CR_STRING[k], value, k);
        if (CR_STRING[k] === value) {
            // console.log("returning", CR[k])
            result = CR[k];
            found = true;
        }
    })
    if (found) return result;
    if (validatePosInt(value)) {
        return parseInt(value);
    }
    return 0;
}

const xpChart = new Map();
xpChart
  .set(CR.ZERO, 10)
  .set(CR.EIGHTH, 25)
  .set(CR.QUARTER, 50)
  .set(CR.HALF, 100)
  .set(1, 200)
  .set(2, 450)
  .set(3, 700)
  .set(4, 1100)
  .set(5, 1800)
  .set(6, 2300)
  .set(7, 2900)
  .set(8, 3900)
  .set(9, 5000)
  .set(10, 5900)
  .set(11, 7200)
  .set(12, 8400)
  .set(13, 10000)
  .set(14, 11500)
  .set(15, 13000)
  .set(16, 15000)
  .set(17, 18000)
  .set(18, 20000)
  .set(19, 22000)
  .set(20, 25000)
  .set(21, 33000)
  .set(22, 41000)
  .set(23, 50000)
  .set(24, 62000)
  .set(25, 75000)
  .set(26, 90000)
  .set(27, 105000)
  .set(28, 120000)
  .set(29, 135000)
  .set(30, 155000)

export const getCRDataSet = () => {
    const list = [];
    const keys = Object.keys(CR_STRING);
    keys.forEach((k, i) => {
        list.push({ cr: CR_STRING[k]})
    })
    for (let i = 1; i <= 30; i++) {
        list.push({ cr: i + ""});
    }

    return list;
}

export const getXpByCr = (cr: number | undefined) => {
    if (!cr) return 0;
    return xpChart.get(cr);
}

export default CR