// import { addNewCondition, addNewCreatureType, addNewDamageType, addNewLanguage, addNewMovement, addNewSense, addNewSize, addNewSkill, addNewSource } from "redux/creatureStatBlockItemSlice";
import { addCreature } from "../redux/slices/creatureSlice";
import { addMagicItem } from "../redux/slices/magicItemSlice";
import { addSpell } from "../redux/slices/spellSlice";
import store from "../redux/store";
import APIInterface from "./APIInterface";

//Authentication
export const loginUser = async (email, password) => {

    const res = await APIInterface.signIn({ email, password })

    if (res.status === 201) return (await res.json()).token
    return null
}

//Creatures
export const createCreature = async (creature, authToken) => {

    const res = await APIInterface.postCreature(creature, authToken)

    if (res.ok) {
        const data = await res.json();
        store.dispatch(addCreature(data.data));
        return data.data;
    }
    return null
}

export const createMagicItem = async (magicItem, authToken) => {

    const res = await APIInterface.postMagicItem(magicItem, authToken)

    if (res.ok) {
        const data = await res.json();
        store.dispatch(addMagicItem(data.data));
        return data.data;
    }
    return null
}

export const createSpell = async (spell, authToken) => {

    const res = await APIInterface.postSpell(spell, authToken)

    if (res.ok) {
        const data = await res.json();
        store.dispatch(addSpell(data.data));
        return data.data;
    }
    return null
}

export const postItem = async (endpoint, body, authToken) => {

    const res = await APIInterface.postItem(endpoint, body, authToken)

    if (res.ok) {
        const d = await res.json();
        console.log("postItem", d);
        return d.data
    }

    return null
}

// export const postCondition = async (dispatch, body, authToken) => {
//     const res = await postItem('condition', body, authToken)
//     dispatch(addNewCondition(res))
//     console.log('post condition', res)
//     return res
// }

// export const postCreatureType = async (dispatch, body, authToken) => {
//     const res = await postItem('creatureType', body, authToken)
//     dispatch(addNewCreatureType(res))
//     console.log('post creature type', res)
//     return res
// }

// export const postDamageType = async (dispatch, body, authToken) => {
//     const res = await postItem('damageType', body, authToken)
//     dispatch(addNewDamageType(res))
//     console.log('post damage type', res)
//     return res
// }

// export const postLanguage = async (dispatch, body, authToken) => {
//     const res = await postItem('language', body, authToken)
//     dispatch(addNewLanguage(res))
//     console.log('post language', res)
//     return res
// }

// export const postMovement = async (dispatch, body, authToken) => {
//     const res = await postItem('movement', body, authToken)
//     if (res) {
//         dispatch(addNewMovement(res))
//         console.log('post movement', res)
//     }
//     return res
// }

// export const postSense = async (dispatch, body, authToken) => {
//     const res = await postItem('sense', body, authToken)
//     dispatch(addNewSense(res))
//     console.log('post sense', res)
//     return res
// }

// export const postSize = async (dispatch, body, authToken) => {
//     const res = await postItem('size', body, authToken)
//     dispatch(addNewSize(res))
//     console.log('post size', res)
//     return res
// }

// export const postSkill = async (dispatch, body, authToken) => {
//     const res = await postItem('skill', body, authToken)
//     dispatch(addNewSkill(res))
//     console.log('post skill', res)
//     return res
// }

// export const postSource = async (dispatch, body, authToken) => {
//     const res = await postItem('source', body, authToken)
//     if (res) {
//         dispatch(addNewSource(res))
//         console.log('post source', res)
//     }
//     return res
// }

// //General
