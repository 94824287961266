import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ModalState {
    addInitItemActive: boolean
    editInitItemActive: boolean
    initHpActive: boolean
    deathSavesActive: boolean
    deleteInitItemActive: boolean
    rerollInitActive: boolean
    clearInitActive: boolean
    xpActive: boolean
    viewInitCreatureActive: boolean;
    viewCreatureActive: boolean
    addCreatureActive: boolean
    viewMagicItemActive: boolean;
    addMagicItemActive: boolean;
    viewSpellActive: boolean;
    addSpellActive: boolean;
    filterCreatureActive: boolean;
    filterSpellActive: boolean;
    filterMagicItemActive: boolean;
    randomMagicItemActive: boolean;
    confirmActive: boolean;
    shareActrive: boolean;
}

const initialState: ModalState = {
    addInitItemActive: false,
    editInitItemActive: false,
    initHpActive: false,
    deleteInitItemActive: false,
    rerollInitActive: false,
    clearInitActive: false,
    xpActive: false,
    viewInitCreatureActive: false,
    viewCreatureActive: false,
    addCreatureActive: false,
    viewMagicItemActive: false,
    addMagicItemActive: false,
    viewSpellActive: false,
    addSpellActive: false,
    filterCreatureActive: false,
    filterSpellActive: false,
    filterMagicItemActive: false,
    randomMagicItemActive: false,
    confirmActive: false,
    shareActrive: false,
    // createConditionActive: false,
    // createCreatureTypeActive: false,
    // createDamageTypeActive: false,
    // createLanguageActive: false,
    // createMovementActive: false,
    // createSenseActive: false,
    // createSizeActive: false,
    // createSkillActive: false,
    // createSourceActive: false,
    // createTemplateActive: false,

    // creatureFilterActive: false,
    deathSavesActive: false,
    // deleteCreatureActive: false,
    // editCreatureActive: false,
    // hpActive: false,
    // rerollInitiativeActive: false,
    // xpSettingsActive: false,
}

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setAddInitItemActive: (state, action: PayloadAction<boolean>) => {
            state.addInitItemActive = action.payload
        },
        setEditInitItemActive: (state, action: PayloadAction<boolean>) => {
            state.editInitItemActive = action.payload
        },
        setInitHpActive: (state, action: PayloadAction<boolean>) => {
            state.initHpActive = action.payload
        },
        setDeleteInitItemActive: (state, action: PayloadAction<boolean>) => {
            state.deleteInitItemActive = action.payload
        },
        setRerollInitActive: (state, action: PayloadAction<boolean>) => {
            state.rerollInitActive = action.payload
        },
        setClearInitActive: (state, action: PayloadAction<boolean>) => {
            state.clearInitActive = action.payload
        },
        setXpActive: (state, action: PayloadAction<boolean>) => {
            state.xpActive = action.payload
        },
        setAddCreatureActive: (state, action: PayloadAction<boolean>) => {
            state.addCreatureActive = action.payload;
        },
        setViewMagicItemActive: (state, action: PayloadAction<boolean>) => {
            state.viewMagicItemActive = action.payload;
        },
        setAddMagicItemActive: (state, action: PayloadAction<boolean>) => {
            state.addMagicItemActive = action.payload;
        },
        setAddSpellActive: (state, action: PayloadAction<boolean>) => {
            state.addSpellActive = action.payload;
        },
        setViewSpellActive: (state, action: PayloadAction<boolean>) => {
            state.viewSpellActive = action.payload;
        },
        setFilterCreatureActive: (state, action: PayloadAction<boolean>) => {
            state.filterCreatureActive = action.payload;
        },
        setFilterSpellActive: (state, action: PayloadAction<boolean>) => {
            state.filterSpellActive = action.payload;
        },
        setFilterMagicItemActive: (state, action: PayloadAction<boolean>) => {
            state.filterMagicItemActive = action.payload;
        },
        setRandomMagicItemActive: (state, action: PayloadAction<boolean>) => {
            state.randomMagicItemActive = action.payload;
        },
        setConfirmActive: (state, action: PayloadAction<boolean>) => {
            state.confirmActive = action.payload;
        },
        setShareActive: (state, action: PayloadAction<boolean>) => {
            state.shareActrive = action.payload;
        },

        // setCreateConditionActive: (state, action) => {
        //     state.createConditionActive = action.payload
        // },
        // setCreateCreatureTypeActive: (state, action) => {
        //     state.createCreatureTypeActive = action.payload
        // },
        // setCreateDamageTypeActive: (state, action) => {
        //     state.createDamageTypeActive = action.payload
        // },
        // setCreateLanguageActive: (state, action) => {
        //     state.createLanguageActive = action.payload
        // },
        // setCreateMovementActive: (state, action) => {
        //     state.createMovementActive = action.payload
        // },
        // setCreateSenseActive: (state, action) => {
        //     state.createSenseActive = action.payload
        // },
        // setCreateSizeActive: (state, action) => {
        //     state.createSizeActive = action.payload
        // },
        // setCreateSkillActive: (state, action) => {
        //     state.createSkillActive = action.payload
        // },
        // setCreateSourceActive: (state, action) => {
        //     state.createSourceActive = action.payload
        // },
        // setCreateTemplateActive: (state, action) => {
        //     state.createTemplateActive = action.payload
        // },

        // setCreatureFilterActive: (state, action) => {
        //     state.creatureFilterActive = action.payload
        // },
        setDeathSavesActive: (state, action: PayloadAction<boolean>) => {
            state.deathSavesActive = action.payload
        },
        // setDeleteCreatureActive: (state, action) => {
        //     state.deleteCreatureActive = action.payload
        // },
        // setEditCreatureActive: (state, action) => {
        //     state.editCreatureActive = action.payload
        // },
        // setHPActive: (state, action) => {
        //     state.hpActive = action.payload
        // },
        // setRerollInitiativeActive: (state, action) => {
        //     state.rerollInitiativeActive = action.payload
        // },
        setViewInitCreatureActive: (state, action: PayloadAction<boolean>) => {
            state.viewInitCreatureActive = action.payload
        },
        setViewCreatureActive: (state, action) => {
            state.viewCreatureActive = action.payload
        }
        // setXPSettingsActive: (state, action) => {
        //     state.xpSettingsActive = action.payload
        // },
    },
})

// Action creators are generated for each case reducer function
export const {
    setAddInitItemActive,
    setEditInitItemActive,
    setInitHpActive,
    setDeleteInitItemActive,
    setRerollInitActive,
    setClearInitActive,
    setXpActive,
    setAddCreatureActive,
    setViewMagicItemActive,
    setAddMagicItemActive,
    setAddSpellActive,
    setViewSpellActive,
    setFilterCreatureActive,
    setFilterSpellActive,
    setFilterMagicItemActive,
    setRandomMagicItemActive,
    setConfirmActive,
    setShareActive,
    // setCreateConditionActive,
    // setCreateCreatureTypeActive,
    // setCreateDamageTypeActive,
    // setCreateLanguageActive,
    // setCreateMovementActive,
    // setCreateSenseActive,
    // setCreateSizeActive,
    // setCreateSkillActive,
    // setCreateSourceActive,
    // setCreateTemplateActive,

    // setCreatureFilterActive,
    setDeathSavesActive, 
    // setDeleteCreatureActive,
    // setEditCreatureActive,
    // setHPActive, 
    // setRerollInitiativeActive,
    setViewInitCreatureActive,
    setViewCreatureActive,
    // setXPSettingsActive,
} = modalSlice.actions

export const selectAddInitItemActive = (state: RootState) => state.modal.addInitItemActive
export const selectEditInitItemActive = (state: RootState) => state.modal.editInitItemActive
export const selectInitHpActive = (state: RootState) => state.modal.initHpActive
export const selectDeleteInitItemActive = (state: RootState) => state.modal.deleteInitItemActive
export const selectRerollinitActive = (state: RootState) => state.modal.rerollInitActive
export const selectClearInitActive = (state: RootState) => state.modal.clearInitActive
export const selectXpActive = (state: RootState) => state.modal.xpActive
export const selectAddCreatureActive = (state: RootState) => state.modal.addCreatureActive;
export const selectViewMagicItemActive = (state: RootState) => state.modal.viewMagicItemActive;
export const selectAddMagicItemActive = (state: RootState) => state.modal.addMagicItemActive;
export const selectViewSpellActive = (state: RootState) => state.modal.viewSpellActive;
export const selectAddSpellActive = (state: RootState) => state.modal.addSpellActive;
export const selectFilterCreatureActive = (state: RootState) => state.modal.filterCreatureActive;
export const selectFilterSpellActive = (state: RootState) => state.modal.filterSpellActive;
export const selectFilterMagicItemActive = (state: RootState) => state.modal.filterMagicItemActive;
export const selectRandomMagicItemActive = (state: RootState) => state.modal.randomMagicItemActive;
export const selectConfirmActive = (state: RootState) => state.modal.confirmActive;
export const selectShareActive = (state: RootState) => state.modal.shareActrive;

// export const selectCreateConditionActive = state => state.modals.createConditionActive
// export const selectCreateCreatureTypeActive = state => state.modals.createCreatureTypeActive
// export const selectCreateDamageTypeActive = state => state.modals.createDamageTypeActive
// export const selectCreateLanguageActive = state => state.modals.createLanguageActive
// export const selectCreateMovementActive = state => state.modals.createMovementActive
// export const selectCreateSenseActive = state => state.modals.createSenseActive
// export const selectCreateSizeActive = state => state.modals.createSizeActive
// export const selectCreateSkillActive = state => state.modals.createSkillActive
// export const selectCreateSourceActive = state => state.modals.createSourceActive
// export const selectCreateTemplateActive = state => state.modals.createTemplateActive

// export const selectCreatureFilterActive = state => state.modals.creatureFilterActive
export const selectDeathSavesActive = (state: RootState) => state.modal.deathSavesActive
// export const selectDeleteCreatureActive = state => state.modals.deleteCreatureActive
// export const selectEditCreatureActive = state => state.modals.editCreatureActive
// export const selectHPActive = state => state.modals.hpActive
// export const selectRerollInitiativeActive = state => state.modals.rerollInitiativeActive
export const selectViewinitCreatureActive = (state: RootState) => state.modal.viewInitCreatureActive;
export const selectViewCreatureActive = (state: RootState) => state.modal.viewCreatureActive
// export const selectXPSettingsActive = state => state.modals.xpSettingsActive

export default modalSlice.reducer