import axios from "axios";
import { API_BASE_URL, BASE_URL } from "./api.constants";
import { AuthItem, User } from "../types/UserTypes";
import { getConfig } from "./api.helper";

//Authentication
export const loginUser = async (email: string, password: string) => {
    try {
        const res = await axios.post<AuthItem>(
            BASE_URL + "signin",
            {
                email,
                password
            },
            getConfig("")
        );
    
        if (res.status === 201 && res.data) {
            return res.data.token;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error loggin in", e);
    }
}

export const loginUserGoogle = async (googleAccessToken: string) => {
    try {
        const res = await axios.post<AuthItem>(
            BASE_URL + "signin",
            {
                googleAccessToken
            },
            getConfig("")
        );
    
        if (res.status === 201 && res.data) {
            return res.data.token;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error logging in", e);
    }
}

export const signupUser = async (email: string, password: string) => {
    try {
        const res = await axios.post<AuthItem>(
            BASE_URL + "signup",
            {
                email,
                password
            },
            getConfig("")
        );
    
        if (res.status === 201 && res.data) {
            return res.data.token;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error logging in", e);
    }
}

export const getUser = async (authToken: string) => {
    try {
        const res = await axios.get<{ data: User }>(
            API_BASE_URL + "user",
            getConfig(authToken)
        );

        if (res.status === 200) {
            const user: User = res.data.data;
            return user;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error fetching user data", e);
    }
}

export const sendPasswordResetEmail = async (email: string) => {
    try {
        const res = await axios.post<AuthItem>(
            BASE_URL + "requestPasswordReset",
            {
                email
            },
            getConfig("")
        );
    
        return res.status === 200;
    } catch (e) {
        console.log("Error sending password reset email", e);
    }
}

export const resetPassword = async (userId: string, token: string, password: string) => {
    try {
        const res = await axios.post<AuthItem>(
            BASE_URL + "passwordReset",
            {
                userId,
                token,
                password
            },
            getConfig("")
        );

        return res.status === 200;
    } catch (e) {
        console.log("Error resetting password", e);
    }
}

export const callRequestEmailVerification = async (authToken: string) => {
    try {
        const res = await axios.post<AuthItem>(
            API_BASE_URL + "user/requestEmailVerification",
            {},
            getConfig(authToken)
        );
    
        return res.status === 200;
    } catch (e) {
        console.log("Error loggin in", e);
    }
}

export const callEmailVerification = async (userId: string, token: string) => {
    try {
        const res = await axios.put<AuthItem>(
            BASE_URL + "emailVerification",
            {
                userId,
                token
            },
            getConfig("")
        );

        return res.status === 200;
    } catch (e) {
        console.log("Error verifying email", e);
    }
}

export const setNewUsername = async (username: string, authToken: string) => {
    try {
        const res = await axios.put(
            API_BASE_URL + "user/setUsername",
            {
                username
            },
            getConfig(authToken)
        );
        return res.data.data;
    } catch (e) {
        console.log("Error setting username", e);
    }
}