import { Box, Button, Typography, styled } from "@mui/material";
import myTheme from "../../myTheme";
import BorderButton from "../../components/input/BorderButton/BorderButton";
import { ReactComponent as InitiativeIcon } from "../../assets/svg/InitiativeIcon.svg";
import { ReactComponent as CreatureIcon } from "../../assets/svg/CreatureIcon.svg";
import { ReactComponent as SpellsIcon } from "../../assets/svg/SpellsIcon.svg";
import { ReactComponent as ItemsIcon } from "../../assets/svg/ItemsIcon.svg";


const Container = styled(Box)({
    width: "100%",
    minHeight: "100vh",
    backgroundColor: myTheme.bg,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflowY: "scroll"
});

const LoginContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "10px 32px 0 32px"
});

const SubHeading = styled(Typography)({
    color: myTheme.light,
    fontSize: "32px",
    fontWeight: "bold"
});

const BodyContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "48px",
    boxSizing: "border-box",
    padding: "40px 160px"
});

const HeadingContainer = styled(Box)({
    width: "100%",
    padding: "20px 30px",
    backgroundColor: myTheme.primary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0,0,0,0.25)"
});

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "48px",
    fontWeight: "bold"
});

const LightText = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px"
});

const HomeButton = styled(Box)({
    width: "fit-content",
    backgroundColor: myTheme.primary,
    borderRadius: "5px",
    color: myTheme.light,
    fontSize: "32px",
    fontWeight: "bold",
    padding: "12px 20px",
    boxShadow: "0 4px 4px rgba(0,0,0,0.25)"
});

const NavContainer = styled(Box)({
    width: "100%",
    padding: "20px 100px",
    backgroundColor: myTheme.offWhite,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    boxSizing: "border-box",
    borderRadius: "5px",
    boxShadow: "0 4px 4px rgba(0,0,0,0.25)"
});

const NavItem = styled(Box)({
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
});

const DarkText = styled(Typography)({
    color: myTheme.darkest,
    fontSize: "24px"
});

const WebHome = () => {

    return (
        <Container>
            <LoginContainer>
                <SubHeading>Login/Register</SubHeading>
            </LoginContainer>
            <BodyContainer>
                <HeadingContainer>
                    <Heading>DUNGEONSUITE</Heading>
                    <LightText>A Tool For GM's</LightText>
                    <LightText style={{marginTop: "20px"}}>Designed with an intuitive, mobile-first interface, Dungeonsuite ensures that you have all the information you need at your fingertips while running the game</LightText>
                </HeadingContainer>
                <HomeButton>Get Started</HomeButton>
                <NavContainer>
                    <NavItem>
                        <DarkText>Initiative<br/>Tracker</DarkText>
                        <BorderButton
                            id="initButton"
                            targetPath="/"
                            currentPath="/"
                            Icon={
                                <InitiativeIcon
                                    data-testid={"bottomNav__button--initiative"}
                                    height={"100%"}
                                />
                            }
                        />
                    </NavItem>
                    <NavItem>
                        <DarkText>Creature<br/>Manager</DarkText>
                        <BorderButton
                            id="creaturesButton"
                            targetPath="/"
                            currentPath="/"
                            Icon={
                                <CreatureIcon
                                    data-testid={"bottomNav__button--creatures"}
                                    height={"100%"}
                                />
                            }
                        />
                    </NavItem>
                    <NavItem>
                        <DarkText>Spell<br/>Manager</DarkText>
                        <BorderButton
                            id="spellsButton"
                            targetPath="/"
                            currentPath="/"
                            Icon={
                                <SpellsIcon
                                    data-testid={"bottomNav__button--spells"}
                                    height={"100%"}
                                />
                            }
                        />
                    </NavItem>
                    <NavItem>
                        <DarkText>Item<br/>Manager</DarkText>
                        <BorderButton
                            id="itemsButton"
                            targetPath="/"
                            currentPath="/"
                            Icon={
                                <ItemsIcon
                                    data-testid={"bottomNav__button--items"}
                                    height={"100%"}
                                />
                            }
                        />
                    </NavItem>
                </NavContainer>
            </BodyContainer>
        </Container>
    );
};

export default WebHome;