import { ActionCreatorWithPayload } from "@reduxjs/toolkit"
import { postItem } from "../api/postData"
import store from "../redux/store"


export const createNewItems = async (array: any[], itemVarName: string, endpoint: string, reducer: ActionCreatorWithPayload<any, any>) => {

    const newArray = [...array]

    for (let i = 0; i < newArray.length; i++) {
        const s = newArray[i]
        if (s[itemVarName].new) {
            const { _id, ...rest } = s[itemVarName];
            const res = await postItem(endpoint, { ...rest, source: '62081d41cd68980016f931af'}, store.getState().user.authToken)
            console.log("res", res);
            if (res) {
                store.dispatch(reducer(res));
                s[itemVarName] = res
            } else {
                return;
            }
        }
    }
    console.log("newArray", newArray)
    return newArray 
}

export const createNewItemsNoValue = async (array: any[], endpoint: string, reducer: ActionCreatorWithPayload<any, any>) => {

    console.log('making new', endpoint);
    const newArray = [...array];

    for (let i = 0; i < newArray.length; i++) {
        const s = newArray[i]
        if (s.new) {
            const { _id, ...rest } = s;
            const res = await postItem(endpoint, {...rest, source: '62081d41cd68980016f931af'}, store.getState().user.authToken);
            console.log("res", res);
            if (res) {
                store.dispatch(reducer(res));
                newArray[i] = res;
            } else {
                return;
            }
        }
    }

    console.log('done making', endpoint, newArray)

    return newArray
}