import repeatGif from "../../../assets/gif/DungeonsuiteLoadingRepeat.gif";

const DsLoader = () => {
    return (
        <div>
            <img src={repeatGif}
                width={"100%"}
            />
        </div>
    )
};

export default DsLoader;