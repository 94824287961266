
export enum CreatureSearchFilters {
    NAME = "Name",
    CR = "CR",
    TYPE = "Type"
}

export enum SearchOrder {
    ASC = "Asc",
    DESC = "Desc"
}