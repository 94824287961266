import { Box, styled } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { ISaves } from "../../../types/InitiativeTypes";
import myTheme from "../../../myTheme";


const OuterWrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: "center",
}))

const ColWrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column"
}))

const HeartOutline = styled(FavoriteBorderOutlinedIcon)(({ theme }) => ({
    fontSize: "inherit",
    color: myTheme.light
}))

const SuccessfulSave = styled(FavoriteIcon)(({ theme }) => ({
    fontSize: "inherit",
    color: myTheme.healthGreen
}))

const FailedSave = styled(HeartBrokenOutlinedIcon)(({ theme }) => ({
    fontSize: "inherit",
    color: myTheme.healthRed
}))

export interface DeathSavesProps {
    saves: ISaves,
    size: number,
    onClick?: (e: any) => void
}

const DeathSaves = ({ saves, size, onClick }: DeathSavesProps) => {

    return (
        <OuterWrapper onClick={onClick} sx={{fontSize: `${size}px`}} data-testid="deathSaves__wrapper">
            <ColWrapper>
                {saves.fail >= 1 ?
                    <FailedSave data-testid="deathSaves__fail--1" />
                    :
                    <HeartOutline data-testid="deathSaves__failOutline--1" />
                }
                {saves.fail >= 2 ?
                    <FailedSave data-testid="deathSaves__fail--2" />
                    :
                    <HeartOutline data-testid="deathSaves__failOutline--2" />
                }
                {saves.fail >= 3 ?
                    <FailedSave data-testid="deathSaves__fail--3" />
                    :
                    <HeartOutline data-testid="deathSaves__failOutline--3" />
                }
            </ColWrapper>

            <ColWrapper>
                {saves.success >= 1 ?
                    <SuccessfulSave data-testid="deathSaves__success--1" />
                    :
                    <HeartOutline data-testid="deathSaves__successOutline--1" />
                }
                {saves.success >= 2 ?
                    <SuccessfulSave data-testid="deathSaves__success--2" />
                    :
                    <HeartOutline data-testid="deathSaves__successOutline--2" />
                }
                {saves.success >= 3 ?
                    <SuccessfulSave data-testid="deathSaves__success--3" />
                    :
                    <HeartOutline data-testid="deathSaves__successOutline--3" />
                }
            </ColWrapper>
        </OuterWrapper>
    )
}

export default DeathSaves;