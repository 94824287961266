import { Box, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { randomUUID } from "crypto";
import { useEffect, useState } from "react";
import { getCRDataSet, parseCr, parseNumericCR } from "../../../constants/Cr.constants";
import Status from "../../../constants/CreatureStatus";
import Teams, { teamDataSet } from "../../../constants/Teams.constants";
import { validateInt, validatePosInt } from "../../../helper/validation/inputValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { addCreatureToInit } from "../../../redux/slices/initSlice";
import { IInitItem } from "../../../types/InitiativeTypes";
import DsButton from "../../input/DsButton/DsButton";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import DsInput from "../../input/DsInput/DsInput";
import { BiSave } from 'react-icons/bi';
import AdvantagePicker from "../../inititivePageComponents/AdvantagePicker/AdvantagePicker";
import { v4 } from "uuid";
import { selectCreatures } from "../../../redux/slices/creatureSlice";
import StatblockAutocomplete from "../../input/StatblockAutocomplete/StatblockAutocomplete";
import { ICreature } from "../../../types/CreatureTypes";

const crDataSet = getCRDataSet();

const AddInitItemModal = () => {

    const [name, setName] = useState<string>("");
    const [hp, setHp] = useState<string>("1");
    const [ac, setAc] = useState<string>("10");
    const [cr, setCr] = useState<string>("0");
    const [team, setTeam] = useState<string>(Teams.ENEMY);
    const [initMod, setInitMod] = useState<string>("0");
    const [staticInit, setStaticInit] = useState<string>("");
    const [qty, setQty] = useState<string>("1");
    const [secondRoll, setSecondRoll] = useState<number>(0);
    const [statblock, setStatblock] = useState<ICreature | undefined>();

    const dispatch = useAppDispatch();

    const creatures = useAppSelector(selectCreatures);

    useEffect(() => {
        if (statblock) {
            setName(statblock.name);
            setHp(statblock.hit_points + "");
            setAc(statblock.armor_class + "");
            setCr(parseNumericCR(statblock.challenge_rating));
            if (statblock.dexterity) {
                setInitMod(Math.floor((statblock.dexterity - 10) / 2) + "");
            }
        }
    }, [statblock]);

    const reset = () => {
        setName("");
        setHp("1");
        setAc("10");
        setCr("0");
        setTeam(Teams.ENEMY);
        setInitMod("0");
        setStaticInit("");
        setQty("1");
        setSecondRoll(0);
        setStatblock(undefined);
    };

    const handleAdd = () => {

        const hpNum = parseInt(hp)
        const acNum = parseInt(ac)
        const initModNum = parseInt(initMod)
        const staticInitNum = parseInt(staticInit)
        const qtyNum = parseInt(qty)
        const crNum = parseCr(cr);

        const newInitItem: IInitItem & {qty: number} = {
            name,
            nameFiller: name,
            status: Status.ALIVE,
            locked: false,
            team, 
            initiative: parseInt(staticInit),
            hp: hpNum,
            tempHp: 0,
            saves: {
                success: 0,
                fail: 0
            },
            maxHp: hpNum,
            cr: crNum,
            ac: acNum,
            _id: v4(),
            initMod: initModNum,
            secondRoll,
            qty: qtyNum,
            statblock
        }
        dispatch(addCreatureToInit(newInitItem));
        reset();
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Grid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{boxSizing: "border-box"}}>
                <StatblockAutocomplete
                    name="statblock"
                    label="Load From Statblock"
                    data={creatures}
                    value={statblock}
                    setValue={setStatblock}
                />

                <DsInput xs={12} label={"Name"} value={name} setValue={setName} name="name" />

                {/* Row */}
                <DsDropdown xs={8}
                    name="team" 
                    label="Team" 
                    value={team} 
                    setValue={setTeam} 
                    data={teamDataSet} 
                    attribute="team" 
                />
                <DsDropdown xs={4} 
                    name="cr"
                    label="CR" 
                    value={cr} 
                    setValue={setCr} 
                    data={crDataSet} 
                    attribute="cr" 
                />

                {/* Row */}
                <DsInput xs={4} 
                    name="hp"
                    label="HP" 
                    value={hp} 
                    setValue={setHp} 
                    validate={validatePosInt} 
                />
                <DsInput xs={4} 
                    name="ac"
                    label="AC" 
                    value={ac} 
                    setValue={setAc} 
                    validate={validatePosInt} 
                />
                <DsInput xs={4} 
                    name="initMod"
                    label="Init. Mod" 
                    value={initMod} 
                    setValue={setInitMod} 
                    validate={validateInt} 
                />

                {/* Row */}
                <Grid item xs={12}>
                   <AdvantagePicker secondRoll={secondRoll} setSecondRoll={setSecondRoll} /> 
                </Grid>
                

                {/* Row */}
                <DsInput xs={6} 
                    name="statInit"
                    label="Static Initiative" 
                    value={staticInit} 
                    setValue={setStaticInit} 
                    validate={validatePosInt} 
                />
                <DsInput xs={6} 
                    name="qty"
                    label="Quantity" 
                    value={qty} 
                    setValue={setQty} 
                    validate={validatePosInt}
                />

                {/* Row */}
                <DsButton xs={12} 
                    name="add"
                    icon={<BiSave fontSize={"20px"} />}
                    label="Add To Initiative"
                    onClick={handleAdd} 
                />
            </Grid>
        </Box>
    )
}

export default AddInitItemModal;

function uuidv4(): number {
    throw new Error("Function not implemented.");
}
