import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setPath } from "../../redux/slices/dungeonsuiteSlice";
import { resetPassword, sendPasswordResetEmail } from "../../api/authApi";
import DsInput from "../../components/input/DsInput/DsInput";
import { Box, Grid, Typography, styled } from "@mui/material";
import myTheme from "../../myTheme";
import DsButton from "../../components/input/DsButton/DsButton";
import { LOGIN_PATH } from "../../constants/DS.constants";

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    width: "100%"
});

const LinkText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light,
    textDecoration: "underline"
});

const InfoText = styled(Typography)({
    fontSize: "16px",
    color: myTheme.light
});

const ErrorText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.creatureRed
});


const PasswordResetPage = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [userId, setUserId] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [view, setView] = useState<string>("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const u = params.get("id");
        const tok = params.get("token");
        if (u && tok) {
            setUserId(u);
            setToken(tok);
            setView("reset");
        } else {
            setView("requestReset");
        }
    }, []);

    useEffect(() => {
        setError("");
    }, [email]);

    const handleSendEmail = async () => {
        if (email) {
            const res = await sendPasswordResetEmail(email);
            if (res) {
                setView("emailSent");
            } else {
                setError("Failed to send email. Please check that your email is correct and try again.");
            }
        }
    }

    const handleResetPassword = async () => {
        if (password && password === confirmPassword) {
            const res = await resetPassword(userId, token, password);
            if (res) {
                setView("success");
            } else {
                setError("Something went wrong. Your token may have expired. Please try again.");
                setView("requestReset");
            }
        } else {
            setError("Invalid or non-matching passwords");
        }
    };

    const handleReturnToLogin = () => {
        dispatch(setPath(LOGIN_PATH));
        navigate(LOGIN_PATH);
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            padding: "50px 50px"
        }}>
            <Heading sx={{ color: myTheme.primary }}>Dungeonsuite</Heading>
            {view === "requestReset" && (
                <>
                    <Heading>Request Password Reset</Heading>
                    <Grid container rowSpacing="10px">
                        <DsInput
                            name="email"
                            label="Email"
                            value={email}
                            setValue={setEmail}
                        />

                        {error && (
                            <Grid item xs={12} sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <ErrorText>{error}</ErrorText>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <DsButton
                                label="Send Reset Email"
                                onClick={handleSendEmail}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {view === "emailSent" && (
                <>
                    <Heading>Request Password Reset</Heading>
                    <Grid container rowSpacing="10px" sx={{ marginTop: "10px" }}>
                        <InfoText>Email sent. Please check your email for a link to reset your password.</InfoText>

                        <Grid item xs={12}>
                            <DsButton
                                label="Try Again"
                                onClick={() => setView("requestReset")}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {view === "reset" && (
                <>
                    <Heading>Reset Your Password</Heading>
                    <Grid container rowSpacing="10px">
                        <DsInput
                            name="password"
                            label="Password"
                            value={password}
                            setValue={setPassword}
                            type="password"
                        />
                        <DsInput
                            name="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                            type="password"
                        />

                        {error && (
                            <Grid item xs={12} sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <ErrorText>{error}</ErrorText>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <DsButton
                                label="Reset Password"
                                onClick={handleResetPassword}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {view === "success" && (
                <>
                    <Heading>Success!</Heading>
                    <Heading>Your password is reset</Heading>
                    <Grid container rowSpacing="10px">
                        <Grid item xs={12}>
                            <DsButton
                                label="Return to Login"
                                onClick={handleReturnToLogin}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            
            <Grid item xs={12} sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <LinkText onClick={handleReturnToLogin} sx={{ marginTop: "10px" }}>
                    Return to Login
                </LinkText>
            </Grid>
        </Box>
    );
};

export default PasswordResetPage;