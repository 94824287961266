import { Box, Grid, Typography, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectSelectedCreature, setCopyCreature, setSelectedCreature } from "../../../redux/slices/creatureSlice";
import myTheme from "../../../myTheme";
import AcIcon from "../../icons/AcIcon/AcIcon";
import { getXpByCr, parseNumericCR } from "../../../constants/Cr.constants";
import { FaRegHeart } from "react-icons/fa";
import { getAbilityMod } from "../../../helper/creatureHelper/creatureHelper";
import AccordionList, { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import TagScrollView, { Tag } from "../../dataDisplay/TagScrollView/TagScrollView";
import { IDamageType, ILanguage, ISave, ISenseStat, ISkill } from "../../../types/CreatureTypes";
import { ICondition } from "../../../types/StatblockTypes";
import { useEffect, useState } from "react";
import DeleteButton from "../../input/buttons/DeleteButton";
import { selectAuthToken, selectUser } from "../../../redux/slices/userSlice";
import { deleteCreature, deleteSpell } from "../../../api/deleteData";
import { setAddCreatureActive, setAddSpellActive, setViewCreatureActive, setViewSpellActive } from "../../../redux/slices/modalSlice";
import PrimaryButton from "../../input/buttons/PrimaryButton";
import { MdEdit } from "react-icons/md";
import EditButtonLg from "../../input/buttons/EditButtonMd";
import DeleteButtonMd from "../../input/buttons/DeleteButtonMd";
import EditButtonMd from "../../input/buttons/EditButtonMd";
import CopyButtonMd from "../../input/buttons/CopyButtonMd";
import { selectSelectedSpell, setCopySpell, setSelectedSpell } from "../../../redux/slices/spellSlice";
import { getComponentText, getDurationText, getRangeText, getSpellLevelText, getSpellTypeLine1 } from "../../../helper/spellHelper";
import AccordionItem from "../../dataDisplay/AccordionItem/AccordionItem";
import DsButton from "../../input/DsButton/DsButton";
import ShareModal from "../ShareModal/ShareModal";
import { updateSpell } from "../../../api/updateData";

export const ContentGrid = styled(Grid)({
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center"
});

export const SpellDescContainer = styled(Grid)({
    marginTop: "10px",
    width: "100%",
    border: "2px solid " + myTheme.primary,
    borderRadius: "5px",
    color: myTheme.light,
    textAlign: "left",
    padding: "5px 10px",
    boxSizing: "border-box",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
});

const ItalicLabel = styled(Typography)({
    color: myTheme.light,
    fontSize: "14px",
    fontStyle: "italic",
    textAlign: "center"
});

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light
});

const SubTextBold = styled(Typography)({
    fontSize: "16px",
    fontWeight: "bold",
    color: myTheme.light
});

const AttrLabel = styled(Typography)({
    width: "100%",
    padding: "0",
    color: myTheme.light,
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: myTheme.primary,
    borderRadius: "5px",
    boxSizing: "border-box",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px"
});

const AttrText = styled(Typography)({
    width: "100%",
    borderColor: myTheme.primary,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    border: "2px solid " + myTheme.primary,
    borderTopWidth: "0",
    color: myTheme.light,
    textAlign: "center",
    padding: "0",
    boxSizing: "border-box",
    fontSize: "14px",
    minHeight: "23px"
});

interface ViewSpellModalProps {
    disableEditing?: boolean
}

const ViewSpellModal = ({ disableEditing }: ViewSpellModalProps) => {
    const [shareOpen, setShareOpen] = useState<boolean>(false);
    
    const spell = useAppSelector(selectSelectedSpell);
    const user = useAppSelector(selectUser);
    const authToken = useAppSelector(selectAuthToken);

    const dispatch = useAppDispatch();

    useEffect(() => {
        console.log(spell);
    }, [spell]);

    const handleEdit = () => {
        if (spell) {
            dispatch(setSelectedSpell(spell));
            dispatch(setAddSpellActive(true));
            dispatch(setViewSpellActive(false));
        }
    };

    const handleCopy = () => {
        if (spell) {
            dispatch(setSelectedSpell(spell));
            dispatch(setCopySpell(true));
            dispatch(setAddSpellActive(true));
            dispatch(setViewSpellActive(false));
        }
    }

    const handleDelete = async () => {
        await deleteSpell(spell, authToken);
        dispatch(setViewSpellActive(false));
    };

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: "15px"
            }}
        >
            <ContentGrid container>
                <Grid item xs={12}>
                    <Heading>{spell?.name}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <SubTextBold sx={{ textAlign: "center" }}>{getSpellLevelText(spell)}</SubTextBold>
                </Grid>

                <Grid item xs={12}>
                    <SubText sx={{ textAlign: "center" }}>{getSpellTypeLine1(spell)}</SubText>
                </Grid>

                {spell?.concentration && (
                    <Grid item xs={12}>
                        <SubText sx={{ textAlign: "center" }}>Concentration</SubText>
                    </Grid>
                )}

                {spell?.ritual && (
                    <Grid item xs={12}>
                        <SubText sx={{ textAlign: "center" }}>Ritual</SubText>
                    </Grid>
                )}

                <Grid item container xs={12} columnSpacing={"10px"} rowSpacing={"10px"}>
                    <Grid item xs={6}>
                        <AttrLabel>Casting Time</AttrLabel>
                        <AttrText>{getDurationText(spell?.casting_time)}</AttrText>
                    </Grid>
                    <Grid item xs={6}>
                        <AttrLabel>Duration</AttrLabel>
                        <AttrText>{getDurationText(spell?.duration)}</AttrText>
                    </Grid>
                    <Grid item xs={6}>
                        <AttrLabel>Range</AttrLabel>
                        <AttrText>{getRangeText(spell?.range)}</AttrText>
                    </Grid>
                    <Grid item xs={6}>
                        <AttrLabel>Components</AttrLabel>
                        <AttrText>{getComponentText(spell?.components)}</AttrText>
                    </Grid>
                    {spell?.material && (
                        <Grid item xs={12}>
                            <AccordionItem
                                label="Materials"
                                text={spell?.material}
                            />
                        </Grid>
                    )}
                </Grid>

                <SpellDescContainer item container xs={12} direction="row">
                    <Grid item xs={12}>
                        {spell?.description}
                    </Grid>
                </SpellDescContainer>

                {spell?.higher_level && (
                    <Grid item container xs={12}
                        sx={{
                            marginTop: "10px"
                        }}
                    >
                        <Grid item xs={12}>
                            <AccordionItem
                                label="At Higher Levels"
                                text={spell.higher_level}
                            />
                        </Grid>
                    </Grid>
                )}

                <Wrapper>
                    {/* @ts-ignore */}
                    <ItalicLabel>{spell?.source.name + ""}</ItalicLabel>
                </Wrapper>

                <Wrapper sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px"
                }}>
                    <CopyButtonMd onClick={handleCopy} />
                </Wrapper>

                {!disableEditing && (<>
                    {spell?.createdBy === user?._id && (
                        <Wrapper sx={{ gap: "5px" }}>
                        <DsButton
                            label="Share"
                            onClick={() => setShareOpen(true)}
                            buttonProps={{ style: { backgroundColor: myTheme.primary, color: myTheme.light } }}
                        />
                        <Wrapper sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px"
                        }}>
                            <EditButtonMd onClick={handleEdit} />
                            <DeleteButtonMd onClick={handleDelete} confirmDelete />
                        </Wrapper>
                    </Wrapper>
                    )}
                </>)}

                <ShareModal
                    open={shareOpen}
                    setOpen={setShareOpen}
                    onSubmit={async (list) => {
                        await updateSpell({
                            ...spell,
                            sharedWith: list
                        });
                        setShareOpen(false);
                    }}
                    sharedWith={(spell && spell.sharedWith) ? spell.sharedWith : []}
                />
            </ContentGrid>
        </Box>
    );
};

export default ViewSpellModal;